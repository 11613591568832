import '../App.css';
import React, { useState, useEffect } from "react";
import { Button,Select,AutoComplete } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { TradeSignalIndividualTable,HeaderGroup, IndustryStockModal } from './components';
import { useLocation,useNavigate } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { getUserInfo, getIndividualStock } from '../commonFunc';

const { Option } = Select;

const TradeSignalIndividual = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const aveKey = queryParams.get('ave');
  const [tradeTextArray,setTradeTextArray] = useState([]);
  const [isBuy, setIsBuy] = useState(true);
  const [isTrade,setIsTrade] = useState(false);
  const [titleText,setTitleText] = useState("");
  const [individualStockArray,setIndividualStockArray] = useState([]);
  const [selectIndividualStock,setSelectIndividualStock] = useState(queryParams.get('stock') === "null" ? "" : queryParams.get('stock'));
  const [selectIndividualStockTmp,setSelectIndividualStockTmp] = useState(queryParams.get('stock') === "null" ? "" : queryParams.get('stock'));
  const [isShow,setIsShow] = useState(false);
  const [printMode, setPrintMode] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [individualArray,setIndividualArray] = useState([]);
  const [industryName,setIndustryName] = useState([]);
  const navigate = useNavigate();

  function handleIsModal(isModal){
    setIsShow(isModal)
  }

  function handleInputChange(value){
    setSelectIndividualStockTmp(value);
    if (individualStockArray.some(item => item.stockCode === value)) {
      setSelectIndividualStock(value);
    }
  }

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleChangeBtn = () => {
    setIsBuy(!isBuy);
  }

  const getTodayNikkei = (data) => {
    const ranks = Object.values(data).map((item) => item.rank);
    for (let i = 1; i < ranks.length; i++) {
      if(ranks[i - 1] !== undefined){
        if (ranks[i] !== ranks[i - 1] + 1) {
          setIsTrade(false);
          return;
        }
      }
    }
    setIsTrade(true);
    return;
  };

  useEffect(() => {
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        const investmentPerTwenty = (Number(Math.floor(data.user.investment*0.2))).toLocaleString();
        setTradeTextArray([
          {aveKey:20 , buyText:"株価が下がり始めました",sailText:"株価が上がり始めました"},
          {aveKey:30 , buyText:`1回目まず${investmentPerTwenty}円買ってみましょう`,sailText:`1回目まず${investmentPerTwenty}円売ってみましょう`},
          {aveKey:60 , buyText:`2回目下落中さらに${investmentPerTwenty}円買おう`,sailText:`2回目上昇中さらに${investmentPerTwenty}円売ろう`},
          {aveKey:75 , buyText:`3回目下落中さらに${investmentPerTwenty}円買おう`,sailText:`3回目上昇中さらに${investmentPerTwenty}円売ろう`},
          {aveKey:90 , buyText:`4回目下落中さらに${investmentPerTwenty}円買おう`,sailText:`4回目上昇中さらに${investmentPerTwenty}円売ろう`},
          {aveKey:120 , buyText:`5回目下落中さらに${investmentPerTwenty}円買おう`,sailText:`5回目上昇中さらに${investmentPerTwenty}円売ろう`},
          {aveKey:150 , buyText:`${"【株式バーゲンセール中】最低"+investmentPerTwenty+"円は買おう"}`,sailText:`【高値圏に入りました】最低${investmentPerTwenty}円は売ろう`},
        ]);
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
    getIndividualStock().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        setIndividualStockArray(data.nikkeiArray);

      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });

    getIndustryData(selectIndividualStock);

  },[]);

  const getIndustryData = async (stockCode) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/individualStock/get/industryName/stockCode', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({stockCode}),
      });
        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            setIndividualArray(data.nikkeiArray);
            setIndustryName(data.nikkeiArray[0].industryName)
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  }

  useEffect(() => {
    if(queryParams.get('stock') !== selectIndividualStock){
      window.location.href = `/IndividualStock/TradeSignal?ave=${aveKey}&stock=${selectIndividualStock}`;
    }
  },[selectIndividualStock]);

  useEffect(()=>{
    switch(aveKey){
      case "20":
        setTitleText("売買信号(20日平均)");
        break;
      case "30":
        setTitleText("売買信号(30日平均)");
        break;
      case "60":
        setTitleText("売買信号(60日平均)");
        break; 
      case "75":
        setTitleText("売買信号(75日平均)");
        break;    
      case "90":
        setTitleText("売買信号(90日平均)");
        break;
      case "120":
        setTitleText("売買信号(120日平均)");
        break;
      case "150":
        setTitleText("売買信号(150日平均)");
        break;    
    }
  },[aveKey]);

  const handlePrint = () =>{
    setIsPrintModal(true);
  }

  useEffect(() => {
    if(printMode){
      window.print();
    }
  }, [printMode]);
  
  
  useEffect(() => {
    const afterPrint = () => setPrintMode(false);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  return (

    <div className='back-content'>

      <HeaderGroup handlePrint={handlePrint}/>
      <IndustryStockModal individualArray={individualArray} isShow={isShow} handleIsModal={handleIsModal}/>
      <PullToRefresh onRefresh={handleRefresh}>
        <div className='body-content'>
        <div class="signal-label" style={{backgroundColor:"#888",color:"#fff"}}>
          <div>
            <span>{titleText}　</span>
              {(selectIndividualStock && selectIndividualStock !== "" &&  individualStockArray && individualStockArray.length > 0) &&
                <span style={{color:"#fff"}}>{(individualStockArray.find(stock => stock.stockCode === selectIndividualStock)).stockName}({selectIndividualStock})</span>
              }
              <span>【{industryName}】</span>
          </div>
          <div>
            {/* <Select defaultValue={selectIndividualStock} style={{ width: 160 }} onChange={handleChange}>
              {individualStockArray && individualStockArray.map(data => (
                <Option value={data.stockCode}>{data.stockName}({data.stockCode})</Option>
              ))}
            </Select> */}
            <span className='print-no-display'>
              <AutoComplete
                value={selectIndividualStockTmp}
                style={{ minWidth: 200 }}
                options={individualStockArray.map(data => ({
                  value: data.stockCode,
                  label: `${data.stockName}(${data.stockCode})`
                }))}
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={handleInputChange}
              />
              <Button  icon={<SearchOutlined />} onClick={e => handleIsModal(true)}>
              </Button>
            </span>
          </div>
          <div style={{fontSize:"85%",margin:"0 2px"}} className='print-no-display'>
            <p>銘柄コードを入力または入力欄をリセットして銘柄を選択</p>
            <p>虫眼鏡アイコンをタップで同業種の銘柄を検索できます</p>
          </div>
        </div>
          <div className='body-header'>
            {tradeTextArray && tradeTextArray.length > 0 &&
              <div className='signal-text'>{isTrade ? isBuy ? <span style={{color:"red"}}>{(tradeTextArray.find(item => item.aveKey == aveKey)).buyText}</span> : <span style={{color:"green"}}>{(tradeTextArray.find(item => item.aveKey == aveKey)).sailText}</span> : ""}</div>
            }
            <Button  className="change-button" onClick={handleChangeBtn} style={{ backgroundColor: isBuy ? "rgba(0,255,0,0.5)" : "rgba(255,0,0,0.5)" }}>
              {isBuy ? <>売信号に切替</> : <>買信号に切替</>}
            </Button>
          </div>
          {isBuy ? <div class="signal-label" style={{backgroundColor:"rgba(255,0,0,0.5)"}}>買信号を表示中</div> : <div class="signal-label" style={{backgroundColor:"rgba(0,255,0,0.5)"}}>売信号を表示中</div>}
          <TradeSignalIndividualTable aveKey={aveKey} isBuy={isBuy} stock={selectIndividualStock} getTodayNikkei={getTodayNikkei} printMode={printMode} setPrintMode={setPrintMode} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
        </div>
      </PullToRefresh>
    </div>

  );
};

export default TradeSignalIndividual;
