import { Spin } from 'antd';
import './components.css';

const Loading = (data) => {
    const {loading} = data;
    return (
        <>
            {loading &&
                <div className='black-view'>
                    <div><Spin spinning={loading} size="large" /></div>
                </div>
            }
        </>
    );
};
export default Loading;
