import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Login';
import PasswordReset from './PasswordReset';
import ChangePassword from './ChangePassword';
import NikkeiStock from './NikkeiStock';
import MyPage from './MyPage';
import EditUserInfo from './EditUserInfo';
import InsertUserInfo from './InsertUserInfo';
import TradeSignal from './TradeSignal';
import InsertNikkeiStockManual from './InsertNikkeiStockManual';
import TwelvePsychology from './TwelvePsychology';
import TradeSignalW from './TradeSignalW';
import UpDownRatio from './UpDownRatio';
import Explanation from './Explanation';
import NikkeiStockFeature from './NikkeiStockFeature';
import UsersList from './UsersList';
import Manual from './Manual';
import TradeSignalWRank from './TradeSignalWRank';
import TradeSignalWAveDiff from './TradeSignalWAveDiff';
import TradeSignalIndividual from './IndividualStock/TradeSignalIndividual';
import TradeSignalWIndividual from './IndividualStock/TradeSignalWlndividual';
import TradeSignalWAveDiffIndividual from './IndividualStock/TradeSignalWAveDiffIndividual';
import TradeSignalWRankIndividual from './IndividualStock/TradeSignalWRankIndividual';
import TwelvePsychologyIndividual from './IndividualStock/TwelvePsychologyIndividual';
import IndustryStock from './IndividualStock/IndustryStock';
import TradeSignalAll from './IndividualStock/TradeSignalAll';
import Fundamentals from './IndividualStock/Fundamentals';
import MyHoldings from './IndividualStock/MyHoldings';
import InsertMyHoldings from './IndividualStock/InsertMyHoldings';
import UpDownRatioIndividual from './IndividualStock/UpDownRatioIndividual';
import ManualIndividual from './IndividualStock/ManualIndividual';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={< Login/>} />
        <Route path="/PasswordReset" element={< PasswordReset/>} />
        <Route path="/ChangePassword" element={< ChangePassword/>} />
        <Route path="/NikkeiStock" element={<NikkeiStock/>} />
        <Route path="/MyPage" element={<MyPage/>} />
        <Route path="/UsersList" element={<UsersList/>} />
        <Route path="/EditUserInfo" element={<EditUserInfo/>} />
        <Route path="/InsertUserInfo" element={<InsertUserInfo/>} />
        <Route path="/TradeSignal" element={<TradeSignal/>} />
        <Route path="/TradeSignalW" element={<TradeSignalW/>} />
        <Route path="/TradeSignalWRank" element={<TradeSignalWRank/>} />
        <Route path="/TradeSignalWAveDiff" element={<TradeSignalWAveDiff/>} />
        <Route path="/InsertNikkeiStockManual" element={<InsertNikkeiStockManual/>} />
        <Route path="/TwelvePsychology" element={<TwelvePsychology/>} />
        <Route path="/UpDownRatio" element={<UpDownRatio/>} />
        <Route path="/Explanation" element={<Explanation/>} />
        <Route path="/NikkeiStockFeature" element={<NikkeiStockFeature/>} />
        <Route path="/Manual" element={<Manual/>} />
        <Route path="/IndividualStock/IndustryStock" element={<IndustryStock/>} />
        <Route path="/IndividualStock/TradeSignal" element={<TradeSignalIndividual/>} />
        <Route path="/IndividualStock/TradeSignalW" element={<TradeSignalWIndividual/>} />
        <Route path="/IndividualStock/TradeSignalWAveDiff" element={<TradeSignalWAveDiffIndividual/>} />
        <Route path="/IndividualStock/TradeSignalWRank" element={<TradeSignalWRankIndividual/>} />
        <Route path="/IndividualStock/TwelvePsychology" element={<TwelvePsychologyIndividual/>} />
        <Route path="/IndividualStock/TradeSignalAll" element={<TradeSignalAll/>} />
        <Route path="/IndividualStock/Fundamentals" element={<Fundamentals/>}/>
        <Route path="/IndividualStock/MyHoldings" element={<MyHoldings/>}/>
        <Route path="/IndividualStock/InsertMyHoldings" element={<InsertMyHoldings/>}/>
        <Route path="/IndividualStock/UpDownRatioIndividual" element={<UpDownRatioIndividual />}/>
        <Route path="/IndividualStock/ManualIndividual" element={<ManualIndividual />}/>
      </Routes>
  </BrowserRouter>
  );
}
export default App;
