import './App.css';
import {React, useState,useEffect} from "react";
import { TradeSignalWRankTable,HeaderGroup } from './components';
import PullToRefresh from 'react-simple-pull-to-refresh';

const TradeSignalWRank= () => {
  const [isTrade,setIsTrade] = useState(false);
  const [printMode, setPrintMode] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);

  const handleRefresh = () => {
    window.location.reload();
  };

  const getTodayNikkei = (data) => {
    const ranks = Object.values(data).map((item) => item.rank);
    for (let i = 1; i < ranks.length; i++) {
      if(ranks[i - 1] !== undefined){
        if (ranks[i] !== ranks[i - 1] + 1) {
          setIsTrade(false);
          return;
        }
      }
    }
    setIsTrade(true);
    return;
  };

  const handlePrint = () =>{
    setIsPrintModal(true);
  }

  useEffect(() => {
    if(printMode){
        window.print();
    }
  }, [printMode]);
  
  
  useEffect(() => {
    const afterPrint = () => setPrintMode(false);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  return (

    <div className='back-content'>
      <HeaderGroup handlePrint={handlePrint}/>
      <PullToRefresh onRefresh={handleRefresh}>
        <div className='body-content'>
          <div class="signal-label" style={{backgroundColor:"#888",color:"#fff"}}>移動平均線順位信号</div>
          <TradeSignalWRankTable getTodayNikkei={getTodayNikkei} printMode={printMode} setPrintMode={setPrintMode} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
        </div>
      </PullToRefresh>
    </div>

  );
};

export default TradeSignalWRank;
