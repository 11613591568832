// src/DataTable.js
import './components.css';
import {Modal,Button,Checkbox} from 'antd';
import React, { useState, useEffect } from "react";

const ExplanationModal = ({title,columnName,text,isExplanation}) => {

  const [isModal,setIsModal] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    setIsHidden(false);
    setIsModal(isExplanation);
  },[isExplanation,columnName]);

  const handleChange = (e) =>{
      setIsHidden(e.target.checked);
  }
  const handleClose = () =>{
    setIsModal(false);
    const fetchData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_DIR+'/userInfo/update/explanation/other', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({isHidden,columnName}),
            });
  
            const data = await response.json();
            if(data){
              if(data.isError){
                alert(data.errorText);
              }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } 
      };
      fetchData();
  }

  return (
    <Modal
      title={title}
      open={isModal}
      onCancel={() => handleClose()}
      footer={[
        <span style={{textAlign:"start"}}>{text}</span>,
        <>
          <div style={{margin:"20px 0"}}>
            <Checkbox onChange={handleChange} checked={isHidden}>次回以降この説明文を表示しない場合はチェックを入れてください</Checkbox>
          </div>
          <div style={{margin:"20px 0"}}>
            <Button type="primary"  onClick={handleClose}>
                閉じる
            </Button>
          </div>
        </>
      ]}
    >
    </Modal>
  );
};

export default ExplanationModal;
