import './App.css';
import React, { useState, useEffect } from "react";
import { getUserInfo} from './commonFunc';
import { Form, Input, Button,Radio, Checkbox} from 'antd';
import { UserOutlined,MailOutlined,DatabaseOutlined,MoneyCollectOutlined } from '@ant-design/icons';
import { HeaderGroup } from './components';
import { useNavigate,useLocation } from 'react-router-dom';
import moment from 'moment';
import { Spin } from 'antd';

const { TextArea } = Input; 

const EditUserInfo = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('id');
  const [error, setError] = useState('');
  const [userInfo,setUserInfo] = useState(null);
  const [name,setName] = useState('');
  const [mail,setMail] = useState('');
  const [investment,setInvestment] = useState(0);
  const [investmentCopy,setInvestmentCopy] = useState("");
  const [isAuto,setIsAuto] = useState(true);
  const [isAdmin,setIsAdmin] = useState(false);
  const [isAdminTmp,setIsAdminTmp] = useState(false);
  const [isIndividualStock,setIsIndividualStock] = useState(false);
  const [manualChangeDate,setManualChangeDate] = useState(null);
  const [isExplanation,setIsExplanation] = useState(false);
  const [remarks,setRemarks] = useState(null);
  const [isMailRev,setIsMailRev] = useState(false);
  const navigate = useNavigate();
  const defaultDate = moment();
  const onSubmit = (values) => {
    const fetchData = async () => {
      try {
          const response = await fetch(process.env.REACT_APP_API_DIR+'/userInfo/update', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({name:values.name,mail:values.email,investment:values.investment,isAuto,manualChangeDate:isAuto ? null : values.manualChangeDate,isAdmin:isAdminTmp,isIndividualStock,remarks,isMailRev,userId}),
          });
          const data = await response.json();
          if(data){
            if(data.isError){
              setError(data.errorText);
            }
            else{
              if(isExplanation){
                const {isError,errorText} = await updateIsExplanation(isExplanation);
                if(isError){
                  setError(errorText);
                  return;
                }
              }
              console.log(data.errorText);
              alert(data.errorText);
              if(userId){
                navigate("/UsersList");
              }
              else{
                navigate("/MyPage");
              } 
            }
          }
          else{
            setError("ネットワークエラーです");
          }
      } catch (error) {
          setError("ネットワークエラーです");
          console.error('Fetch error:', error);
      }
    };
    fetchData();
  };

  const updateIsExplanation = async (isExplanation) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/userInfo/update/explanation/all', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({isExplanation,userId}),
        });

        const data = await response.json();
        if(data){
          return {isError:data.isError,errorText:data.errorText};
        }
        else{
          return {isError:true,errorText:"ネットワークエラーです"};
        }
    } catch (error) {
      console.log(error);
      return {isError:true,errorText:"ネットワークエラーです"};
    }
  };

  function  onChange(value){
    setIsAuto(value);
  }

  useEffect(() => {
    getUserInfo(userId).then(data =>{
      if(data.isError){
        alert(data.errorText);
        navigate("/");
      }
      else{
        setIsAdmin(data.user.isAdmin);
        setIsAdminTmp(data.user.isAdmin);
        setIsIndividualStock(data.user.isIndividualStock);
        setName(data.user.name);
        setMail(data.user.mail);
        setInvestment(data.user.investment);
        setInvestmentCopy((Number(data.user.investment)).toLocaleString());
        setIsAuto(data.user.isAuto);
        setManualChangeDate(data.user.manualChangeDate);
        setRemarks(data.user.remarks);
        setIsMailRev(data.user.isMailRev);
        setUserInfo(data.user);
      }
    }).catch(err=>{
      console.log(err);
      alert("ネットワークエラーです");
      navigate("/");
    });
  },[userId]);

  const tapPrev = () => {
    navigate("/MyPage");
  };
  const handleChangeInvestment = (e) => {
    setInvestmentCopy((Number(e.target.value)).toLocaleString());
  }

  return (
    <div className='back-content'>
      <div className='main-content'>
        <HeaderGroup/>
        <div className='body-content'>
        <h2 className='page-title'>ユーザー情報編集</h2>
        {userInfo ? (
          <div className='page-content'>
          <Form
            name="password_change"
            className="page-form"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
          >
            {error && <p style={{ color: 'red', fontSize: '80%' }}>{error}</p>}
            <Form.Item
            
              name="name"
              rules={[{ required: true, message: 'お名前を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>お名前</span>}
              labelCol={{span:24}}
              initialValue={name}
            >
              <Input
                style={{fontSize:"120%"}}
                prefix={<UserOutlined />}
                type="text"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'メールアドレスを入力してください' }]}
              label={<span style={{fontSize:"120%"}}>メールアドレス</span>}
              labelCol={{span:24}}
              initialValue={mail}
            >
              <Input prefix={<MailOutlined />} 
                style={{fontSize:"120%"}}
                type='email'
              />
            </Form.Item>
            <Form.Item
              name="investment"
              rules={[{ required: true, message: '投資金額を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>投資金額(円)</span>}
              labelCol={{span:24}}
              initialValue={investment}
              extra={<>現在の入力金額：{investmentCopy}</>}
            >
              <Input prefix={<MoneyCollectOutlined />}
                style={{fontSize:"120%"}}
                type='number'
                onChange={handleChangeInvestment}
              />
            </Form.Item>
            
            <Form.Item
              name="isAuto"
              rules={[{ required: true, message: '日経平均株価の入力方法を選択してください' }]}
              label={<span style={{fontSize:"120%"}}>日経平均株価の入力方法</span>}
              labelCol={{span:24}}
              initialValue={isAuto}
            >
              <Radio.Group onChange={e=>onChange(e.target.value)} value={isAuto} name="isAuto">
                <Radio value={1}><span style={{fontSize:"120%"}}>自動</span></Radio>
                <Radio value={0}><span style={{fontSize:"120%"}}>手動</span></Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="manualChangeDate"
              label={<span style={{fontSize:"120%"}}>日経平均株価の入力方法を手動に切り替えた日付</span>}
              labelCol={{span:24}}
              initialValue={manualChangeDate === null ? defaultDate.format('YYYY-MM-DD') : moment(manualChangeDate).format('YYYY-MM-DD')}
            >
              <Input prefix={<DatabaseOutlined />} 
                type='date'
                style={{fontSize:"120%"}}
                disabled={isAuto}
              />
            </Form.Item>
            <Form.Item
                  name="isMailRev"
                  rules={[{ required: true, message: 'メールマガジンを受け取るか選択してください' }]}
                  label={<span style={{fontSize:"120%"}}>メールマガジン</span>}
                  labelCol={{span:24}}
                  initialValue={isMailRev}
                >
                <Radio.Group onChange={e=>setIsMailRev(e.target.value)} value={isMailRev} name="isMailRev">
                  <Radio value={1}><span style={{fontSize:"120%"}}>受け取る</span></Radio>
                  <Radio value={0}><span style={{fontSize:"120%"}}>受け取らない</span></Radio>
                </Radio.Group>
              </Form.Item>
            {userId || isAdmin ? (
              <>
                <Form.Item
                  name="isAdmin"
                  rules={[{ required: true, message: '権限を選択してください' }]}
                  label={<span style={{fontSize:"120%"}}>権限</span>}
                  labelCol={{span:24}}
                  initialValue={isAdminTmp}
                >
                <Radio.Group onChange={e=>setIsAdminTmp(e.target.value)} value={isAdminTmp} name="isAdmin">
                  <Radio value={1}><span style={{fontSize:"120%"}}>管理者</span></Radio>
                  <Radio value={0}><span style={{fontSize:"120%"}}>一般ユーザー</span></Radio>
                </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="isIndividualStock"
                  rules={[{ required: true, message: '個別株の閲覧権限を選択してください' }]}
                  label={<span style={{fontSize:"120%"}}>個別株の閲覧権限</span>}
                  labelCol={{span:24}}
                  initialValue={isIndividualStock}
                >
                <Radio.Group onChange={e=>setIsIndividualStock(e.target.value)} value={isIndividualStock} name="isIndividualStock">
                  <Radio value={1}><span style={{fontSize:"120%"}}>あり</span></Radio>
                  <Radio value={0}><span style={{fontSize:"120%"}}>なし</span></Radio>
                </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="remarks"
                  label={<span style={{fontSize:"120%"}}>備考</span>}
                  labelCol={{span:24}}
                  initialValue={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                >
                  <TextArea></TextArea>
                </Form.Item>
              </>
            ) : (
              <></>
            )
            }
            <Form.Item>
              <Checkbox onChange={(e) => setIsExplanation(e.target.checked)}>各種ページを開いたときにもう一度使い方説明を表示する場合はチェックを入れて更新ボタンを押してください</Checkbox>
              <div style={{margin:"20px 0"}}>
                <Button type="default"  className="prev-button" onClick={tapPrev}>
                  戻る
                </Button>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  更新
                </Button>
              </div>
            </Form.Item>
          </Form>
          </div>
        ) : (
          <div className='loading'>
            <Spin spinning={!userInfo} size="large" />
          </div>
        )

        }
        </div>
      </div>
    </div>

  );
};

export default EditUserInfo;
