// src/DataTable.js
import './components.css';
import { UserOutlined,PrinterOutlined } from '@ant-design/icons';
import { Dropdown, Menu,Button } from 'antd';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { loginCheck,getUserInfo} from '../../commonFunc';
import { useNavigate,useLocation } from 'react-router-dom';
import Burger from "./Burger/Burger"

const TopHeader = ({open,setOpen,handlePrint}) => {
  const [isDisable, setIsDisable] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let holdingsPath = `/IndividualStock/MyHoldings?stock=${queryParams.get('stock')}`
  let manualPath = `/IndividualStock/ManualIndividual?stock=${queryParams.get('stock')}`
  const currentURL = location.pathname;
  useEffect(() => {
    loginCheck().then(data =>{
      if(!data){
        navigate("/");
      }
    }).catch(err=>{
      navigate("/");
    });
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        if(!data.user.isIndividualStock){
          navigate("/");
        }
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
  },[]);

  const handleLogout = () => {
    const fetchData = async () => {
      try {
          const response = await fetch(process.env.REACT_APP_API_DIR+'/login/logout', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              alert(data.errorText);
            }
            else{
              alert(data.errorText);
              navigate("/");
            }
          }
      } catch (error) {
          alert("ネットワークエラーです");
          console.error('Fetch error:', error);
      }
    };
    fetchData();
  };

  const menu = (
    <Menu>
      <Menu.Item key="0"><Link to={holdingsPath}>持ち株一覧</Link></Menu.Item>
      <Menu.Item key="1"><Link to={manualPath}>マニュアル</Link></Menu.Item>
      <Menu.Item key="2"><Link to="/NikkeiStock">日経平均株価に戻る</Link></Menu.Item>
      <Menu.Item key="3"><Link onClick={handleLogout}>ログアウト</Link></Menu.Item>
    </Menu>
  );

  useEffect(()=> {
    if(currentURL === "/IndividualStock/IndustryStock" || 
      currentURL === "/IndividualStock/TradeSignal" ||  
      currentURL === "/IndividualStock/TradeSignalW" || 
      currentURL === "/IndividualStock/TradeSignalWRank" || 
      currentURL === "/IndividualStock/TradeSignalWAveDiff" || 
      currentURL === "/IndividualStock/TwelvePsychology" ||
      currentURL === "/IndividualStock/Fundamentals" ||
      currentURL === "/IndividualStock/TradeSignalAll" ||
      currentURL === "/IndividualStock/UpDownRatioIndividual"
    ){
      setIsDisable(false);
    }
    else{
      setIsDisable(true);
    }
  },[currentURL])

  return (
    <div className='top-header'>
      <div className='burger-btn'><Burger open={open} setOpen={setOpen} /></div>
      <div className='app-title'>業種別・個別株</div>

      <div style={{display:"flex",justifyContent:"center",verticalAlign:"middle"}}>
        <div style={{margin:"auto 3px"}} className='print-btn'><Button icon={<PrinterOutlined />} onClick={e =>  handlePrint()} disabled={isDisable}></Button></div>
        <div style={{margin:"auto 3px"}}>
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            <UserOutlined style={{ fontSize: '30px', color: 'white',paddingRight:"15px",cursor:"pointer"}} />
          </Dropdown> 
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
