import './App.css';
import {React, useState,useEffect} from "react";
import { NikkeiStockFeatureTable,HeaderGroup } from './components';
import PullToRefresh from 'react-simple-pull-to-refresh';

const NikkeiStockFeature = () => {
  const [printMode, setPrintMode] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const handleRefresh = () => {
    window.location.reload();
  };

  const handlePrint = () =>{
    setIsPrintModal(true);
  }

  useEffect(() => {
    if(printMode){
      window.print();
    }
  }, [printMode]);
  
  
  useEffect(() => {
    const afterPrint = () => setPrintMode(false);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);
  
  return (

    <div className='back-content'>
      <HeaderGroup handlePrint={handlePrint}/>
      <PullToRefresh onRefresh={handleRefresh}>
        <div className='body-content'>
          <div class="signal-label" style={{backgroundColor:"#888",color:"#fff"}}>日経平均予測値</div>
          <NikkeiStockFeatureTable printMode={printMode} setPrintMode={setPrintMode} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
        </div>
      </PullToRefresh>
    </div>

  );
};

export default NikkeiStockFeature;
