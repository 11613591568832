// src/DataTable.js
import './components.css';
import React, { useEffect } from "react";
import { StyledMenu } from './Navigation.styled';
import { useLocation,useNavigate } from 'react-router-dom';

const aveArray = [20,30,60,75,90,120,150];
const indexArray =['(20日間)','(30日間)','(60日間)','(75日間)','(90日間)','(120日間)','(150日間)'];

const SideNavigation = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1024) {
        setOpen(false);
      }
    }
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function movePage(url) {
    navigate(url);
    setOpen(false);
  }

  const tradeSignalLinks = aveArray.map((ave, index) => {
    const activeClass = (window.location.pathname === "/IndividualStock/TradeSignal" && (new URLSearchParams(window.location.search)).get('ave') == ave) ? "active menu-item" : "menu-item";
  
    return (
      <div key={index} className={activeClass} onClick={() => movePage(`/IndividualStock/TradeSignal?ave=${ave}&stock=${queryParams.get('stock')}`)}>
        売買信号{indexArray[index]}
      </div>
    );
  });


  return (
    <StyledMenu open={open}>
    <div className='side-navigation'>
      <div className="menu-group">
        <div className={window.location.pathname === "/IndividualStock/TradeSignalAll" ? "active menu-item" : "menu-item"} onClick={() => movePage(`/IndividualStock/TradeSignalAll?stock=${queryParams.get('stock')}`)}>
          本日の売買信号一覧
        </div>
        <div className={window.location.pathname === "/IndividualStock/IndustryStock" ? "active menu-item" : "menu-item"} onClick={() => movePage(`/IndividualStock/IndustryStock?stock=${queryParams.get('stock')}`)}>
          業種別銘柄
        </div>
        <div className={window.location.pathname === "/IndividualStock/Fundamentals" ? "active menu-item" : "menu-item"} onClick={() => movePage(`/IndividualStock/Fundamentals?stock=${queryParams.get('stock')}`)}>
          ファンダメンタルズ指標
        </div>
        {tradeSignalLinks}
        <div className={window.location.pathname === "/IndividualStock/TradeSignalW" ? "active menu-item" : "menu-item"} onClick={() => movePage(`/IndividualStock/TradeSignalW?stock=${queryParams.get('stock')}`)}>
          150日間前日対比信号
        </div>
        <div className={window.location.pathname === "/IndividualStock/TradeSignalWRank" ? "active menu-item" : "menu-item"} onClick={() => movePage(`/IndividualStock/TradeSignalWRank?stock=${queryParams.get('stock')}`)}>
          移動平均線順位信号
        </div>
        <div className={window.location.pathname === "/IndividualStock/TradeSignalWAveDiff" ? "active menu-item" : "menu-item"} onClick={() => movePage(`/IndividualStock/TradeSignalWAveDiff?stock=${queryParams.get('stock')}`)}>
          終値対平均値差信号
        </div>
        <div className={window.location.pathname === "/IndividualStock/TwelvePsychology" ? "active menu-item" : "menu-item"} onClick={() => movePage(`/IndividualStock/TwelvePsychology?stock=${queryParams.get('stock')}`)}>
          12日間投資家心理
        </div>
        <div className={window.location.pathname === "/IndividualStock/UpDownRatioIndividual" ? "active menu-item" : "menu-item"} onClick={() => movePage(`/IndividualStock/UpDownRatioIndividual?stock=${queryParams.get('stock')}`)}>
          25日騰落レシオ
        </div>
      </div>
    </div>
    </StyledMenu>
  );
};

export default SideNavigation;
