// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useLayoutEffect} from "react";
import { Table,DatePicker, Button } from 'antd';
import Loading from "./Loading";
import { SearchOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import ExplanationModal from './ExplanationModal';
import { useNavigate } from 'react-router-dom';
import { getUserInfo} from '../commonFunc';
import PrintModal from './PrintModal';
dayjs.locale('ja');

const { RangePicker } = DatePicker;

const NikkeiStockFeatureTable = (data) => {
  const { printMode,setPrintMode,isPrintModal,setIsPrintModal } = data;
  const [adjustedHeight, setAdjustedHeight] = useState(0);

  const [nikkeiArray,setNikkeiArray] = useState([]);
  const [isLoad,setIsLoad] = useState(false);
  
  const [tableDataArray,setTableDataArray] = useState(null);
  const [searchDateRange, setSearchDateRange] = useState([]);

  const [isExplanation,setIsExplanation] = useState(false);

  const navigate = useNavigate();

  const handleDateSearch = (dates) => {
    setSearchDateRange(dates);
  };

  const filterDataSourceByDate = (data) => {
    if (!searchDateRange || searchDateRange.length < 1) {
      setTableDataArray(data);
      return;
    }

    const [start, end] = searchDateRange;
    setTableDataArray( data.filter(item => {
      const itemDate = new Date(item.date);
      (start.$d).setHours(0,0,0,0,0);
      (end.$d).setHours(0,0,0,0,0);
      return itemDate.getTime() >= (start.$d).getTime() && itemDate.getTime() <= (end.$d).getTime();
    }));
  };

  const resetSearchDate = () => {
    setTableDataArray(nikkeiArray);
    setSearchDateRange([]);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoad(true);
          const response = await fetch(process.env.REACT_APP_API_DIR+'/featureCME/get', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              setNikkeiArray(data.nikkeiFeatureArray);
            }
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
      setIsLoad(false);
    };
    fetchData();
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        setIsExplanation(data.user.isExNikkeiStockFeature);
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });

  },[]);

  useEffect(() => {
    resetSearchDate();
  },[nikkeiArray]);

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const columns = [
    { 
      title: '日付',
      dataIndex: 'date',
      key: 'date',
      fixed: printMode ? '' : 'left',
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            value={searchDateRange}
            onChange={dates => {
                handleDateSearch(dates);
            }}
            locale={locale}
          />
          <div style={{ marginTop: 8 }}>
            <Button type="primary" htmlType="submit" onClick={() => {
              filterDataSourceByDate(nikkeiArray);
              confirm();
            }}>
              検索
            </Button>
            <Button type="default"  className="prev-button" onClick={() => {
              resetSearchDate();
              confirm();
            }}>
              リセット
            </Button>
          </div>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color:"#444"}} className='filter-icon'/>,
      render: date => (
        <>
          <span className='date-pc'>
            {(new Date(date)).getFullYear() + "年" + ((new Date(date).getMonth() + 1)) + "月" + (new Date(date).getDate()) + "日"}
          </span>
          <span className='date-smp'>
          {((new Date(date)).getFullYear()).toString().slice(-2) + "/" + ((new Date(date).getMonth() + 1)) + "/" + (new Date(date).getDate())}
          </span>
        </>
      ),
    },
    { title: '急騰価格', dataIndex: 'soaringPrice', key: 'soaringPrice',
    render: (data)=> {
      return (
        <span style={{color:"green"}}>
          {data}
        </span>
      );
    }      
    },
    { title: '急上昇', dataIndex: 'rapidRise', key: 'rapidRise',
      render: (data)=> {
        return (
          <span style={{color:"green"}}>
            {data}
          </span>
        );
      }      
    },
    { title: '高値B', dataIndex: 'highPriceB', key: 'highPriceB' ,
      render: (data)=> {
        return (
          <span style={{color:"blue"}}>
            {data}
          </span>
        );
      }  
    },
    { title: '高値A', dataIndex: 'highPriceA', key: 'highPriceA'  ,
      render: (data)=> {
        return (
          <span style={{color:"blue",fontWeight:"bold",fontSize:"110%"}}>
            {data}
          </span>
        );
      }
    },
    { title: '中央値', dataIndex: 'median', key: 'mediane' ,
      render: (data)=> {
        return (
          <span style={{fontWeight:"bold",fontSize:"110%"}}>
            {data}
          </span>
        );
      }
    },
    { title: '安値A', dataIndex: 'lowPriceA', key: 'lowPriceA' ,
      render: (data)=> {
        return (
          <span style={{color:"orange",fontWeight:"bold",fontSize:"110%"}}>
            {data}
          </span>
        );
      }
    },
    { title: '安値B', dataIndex: 'lowPriceB', key: 'lowPriceB' ,
      render: (data)=> {
        return (
          <span style={{color:"orange"}}>
            {data}
          </span>
        );
      }
    },
    { title: '急下落', dataIndex: 'decline', key: 'declinee' ,
      render: (data)=> {
        return (
          <span style={{color:"red"}}>
            {data}
          </span>
        );
      }
    },
    { title: '急落価格', dataIndex: 'plummetingPrice', key: 'plummetingPrice',
      render: (data)=> {
        return (
          <span style={{color:"red"}}>
            {data}
          </span>
        );
      }  
    },
  ];

  const modalText =
  <>
    <div>本ページは日経平均株価の予測値を確認できるページになります。</div>
    <div>毎日(土日・祝日・年末年始を除く)午前8時にその日の日経平均株価の予測値を算出します。</div>
  </>

const printColumns = [
  { title: '日付', dataIndex: 'date', key: 'date' },
  { title: '急騰価格', dataIndex: 'soaringPrice', key: 'soaringPrice' },
  { title: '急上昇', dataIndex: 'rapidRise', key: 'rapidRise' },
  { title: '高値B', dataIndex: 'highPriceB', key: 'highPriceB' },
  { title: '高値A', dataIndex: 'highPriceA', key: 'highPriceA' },
  { title: '中央値', dataIndex: 'median', key: 'mediane' },
  { title: '安値A', dataIndex: 'lowPriceA', key: 'lowPriceA' },
  { title: '安値B', dataIndex: 'lowPriceB', key: 'lowPriceB' },
  { title: '急下落', dataIndex: 'decline', key: 'declinee' },
  { title: '急落価格', dataIndex: 'plummetingPrice', key: 'plummetingPrice'},

];

const printArray = [{
  date: '日付',
  soaringPrice: '急騰価格',
  rapidRise: '急上昇',
  highPriceB: '高値B',
  highPriceA: '高値A',
  median: '中央値',
  lowPriceA: '安値A',
  lowPriceB: '安値B',
  decline: '急下落',
  plummetingPrice: '急落価格',
}];


const tableScroll = printMode ? { x:false, y: false} : { x: true, y: adjustedHeight };
const pagenation = printMode ? false: { position: ['topRight','bottomRight']};

useEffect(()=> {
  if(!printMode){
    setTableDataArray(nikkeiArray)
  }
},[printMode])

  return (
    <>
      <Loading loading={isLoad}/>
      <PrintModal setPrintMode={setPrintMode} setTableDataArray={setTableDataArray} nikkeiArray={nikkeiArray} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
      <ExplanationModal title="日経平均予測値ページ" columnName="isExNikkeiStockFeature" text={modalText} isExplanation={isExplanation}/>
      {printMode &&
        <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
      }
      <Table dataSource={tableDataArray} columns={columns} bordered scroll={tableScroll} pagination={pagenation} />
    </>
   
  );
}; 

export default NikkeiStockFeatureTable;
