// src/DataTable.js
import './components.css';
import React, { useState, useEffect ,useLayoutEffect} from "react";
import { Table,DatePicker, Button } from 'antd';
import Loading from "./Loading";
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import ExplanationModal from './ExplanationModal';
import { getUserInfo,aveArray } from '../commonFunc';
import PrintModal from './PrintModal';

dayjs.locale('ja');

let aveCount = 0;
let wordArray = [];

const { RangePicker } = DatePicker;

const TradeSignalTable = (data) => {
  const [adjustedHeight, setAdjustedHeight] = useState(0);
  const { isBuy,getTodayNikkei,aveKey,printMode,setPrintMode,isPrintModal,setIsPrintModal } = data;
  const [nikkeiArray,setNikkeiArray] = useState([]);
  const [nikkeiColumns,setNikkeColumns] = useState([]);
  const [isLoad,setIsLoad] = useState(false);
  const [tableDataArray,setTableDataArray] = useState(null);
  const [searchDateRange, setSearchDateRange] = useState([]);
  const [isExplanation,setIsExplanation] = useState(false);
  // const [wordArray,setWordArray] = useState([]);
  const [modalTitle,setModalTitle] = useState("");
  const [columnName,setColumnName] = useState("");
  const [modalText,setModalText] = useState(<></>);
  const navigate = useNavigate();

  const setWordWithAveKey = (data) => {
    switch (aveKey) {
      case "20":
        wordArray = isBuy ? ["下","落","始","め","で","す"] : ["上","昇","始","め","で","す"];
        setModalTitle("売買信号(20日間)ページ");
        setColumnName("isExTradeSignal1");
        setModalText( 
        <>
          <div>このソフトは終値と3日～300日間の平均価格をAI人工知能で分析し、売買場所を赤と緑の枠と買いましょう・売りましょうの文字で知らせる独自ソフトです。</div>
          <div>売買信号➀～➆のページは一列すべてが赤色になれば買い、緑色になれば売りのタイミングです。またその際に画面上部にいくら売買すればよいかが表示されます。</div>
          <div>このページは終値が3,5,7,10,20日間の平均価格より安い時は「下落始めです」の文字と赤色枠で、高い時は「上昇始めです」の文字と緑色枠で知らせる信号が出ます。</div>
          <div>画面右上の「売信号に切替」ボタンを押すと売り信号のページに切り替わります。また「買信号に切替」ボタンを押すと買い信号のページに切り替わります。</div>
        </>);
        setIsExplanation(data.user.isExTradeSignal1);
        aveCount = 6;
        break;
      case "30":
        wordArray = isBuy ? ["一","回","試","し","買","い","す","る"] : ["一","回","試","し","売","り","す","る"];
        setModalTitle("売買信号(30日間)ページ");
        setColumnName("isExTradeSignal2");
        setModalText( 
        <>
          <div>このページは終値が3,5,7,10,20,30日間の平均価格より安くなった時は「一回試し買いする」の文字と赤色枠で、高くなった時は「一回試し売りする」の文字と緑色枠で知らせる信号が出ます。</div>
          <div>日経平均はいつ変化するか不明なので、買わないで上昇されるリスクのため、1回目買信号が出ます。</div>
          <div>尚、今後売買のタイミングの可能性が5回あるので、自己責任で売買を行うこと。</div>
          <div>画面右上の「売信号に切替」ボタンを押すと売り信号のページに切り替わります。また「買信号に切替」ボタンを押すと買い信号のページに切り替わります。</div>
        </>);
        setIsExplanation(data.user.isExTradeSignal2);
        aveCount = 8;
        break;
      case "60":
        wordArray = isBuy ? ["二","回","目","下","落","中","買","お","う"] : ["二","回","目","上","昇","中","売","ろ","う"];
        setModalTitle("売買信号(60日間)ページ");
        setColumnName("isExTradeSignal3");
        setModalText( 
        <>
          <div>このページは終値が3,5,7,10,20,30,60日間の平均価格より安くなった時は「二回目下落中買おう」の文字と赤色枠で、高くなった時は「二回目上昇中売ろう」の文字と緑色枠で知らせる信号が出ます。</div>
          <div>2回目で売買を行うか、その後の4回の売買信号を待って売買を行うか自己責任で判断してください。</div>
          <div>画面右上の「売信号に切替」ボタンを押すと売り信号のページに切り替わります。また「買信号に切替」ボタンを押すと買い信号のページに切り替わります。</div>
        </>);
        setIsExplanation(data.user.isExTradeSignal3);
        aveCount = 9;
        break;
      case "75":
        wordArray = isBuy ? ["三","回","目","下","落","中","買","お","う","よ"] : ["三","回","目","上","昇","中","売","ろ","う","よ"];
        setModalTitle("売買信号(75日間)ページ");
        setColumnName("isExTradeSignal4");
        setModalText( 
        <>
          <div>このページは終値が3,5,7,10,20,30,60,75日間の平均価格より安くなった時は「三回目下落中買おうよ」の文字と赤色枠で、高くなった時は「三回目上昇中売ろうよ」の文字と緑色枠で知らせる信号が出ます。</div>
          <div>3回目で売買を行うか、次の売買信号を待つか自己責任で売買する。</div>
          <div>画面右上の「売信号に切替」ボタンを押すと売り信号のページに切り替わります。また「買信号に切替」ボタンを押すと買い信号のページに切り替わります。</div>
        </>);
        setIsExplanation(data.user.isExTradeSignal4);
        aveCount = 10;
        break;
      case "90":
        wordArray = isBuy ? ["四","回","目","下","落","中","も","う","買","お","う"] : ["四","回","目","上","昇","中","も","う","売","ろ","う"];
        setModalTitle("売買信号(90日間)ページ");
        setColumnName("isExTradeSignal5");
        setModalText( 
        <>
          <div>このページは終値が3,5,7,10,20,30,60,75,90日間の平均価格より安くなった時は「四回目下落中もう買おう」の文字と赤色枠で、高くなった時は「四回目上昇中もう売ろう」の文字と緑色枠で知らせる信号が出ます。</div>
          <div>いままで売買してない人は売買してみる。</div>
          <div>この場所は年間で最安値・最高値の場所ではないので自己判断してください。</div>
          <div>画面右上の「売信号に切替」ボタンを押すと売り信号のページに切り替わります。また「買信号に切替」ボタンを押すと買い信号のページに切り替わります。</div>
        </>);
        setIsExplanation(data.user.isExTradeSignal5);
        aveCount = 11;
        break;
      case "120":
        wordArray = isBuy ? ["五","回","目","下","落","中","も","う","買","お","う","よ"] : ["五","回","目","上","昇","中","も","う","売","ろ","う","よ"];
        setModalTitle("売買信号(120日間)ページ");
        setColumnName("isExTradeSignal6");
        setModalText( 
        <>
          <div>このページは終値が3,5,7,10,20,30,60,75,90,120日間の平均価格より安くなった時は「五回目下落中もう買おうよ」の文字と赤色枠で、高くなった時は「五回目上昇中もう売ろうよ」の文字と緑色枠で知らせる信号が出ます。</div>
          <div>まだ売買していない人は自己責任で売買してみましょう。</div>
          <div>画面右上の「売信号に切替」ボタンを押すと売り信号のページに切り替わります。また「買信号に切替」ボタンを押すと買い信号のページに切り替わります。</div>
        </>);
        setIsExplanation(data.user.isExTradeSignal6);
        aveCount = 12;
        break;
      case "150":
        wordArray = isBuy ? ["株","式","大","バ","ー","ゲ","ン","セ","ー","ル","買","お","う"] : ["高","値","圏","に","入","り","ま","し","た","売","ろ","う","よ"];
        setModalTitle("売買信号(150日間)ページ");
        setColumnName("isExTradeSignal7");
        setModalText( 
        <>
          <div>このページは終値が3,5,7,10,20,30,60,75,90,120,150日間の平均価格より安くなった時は「株式大バーゲンセール買おう」の文字と赤色枠で、高くなった時は「高値圏に入りました売ろうよ」の文字と緑色枠で知らせる信号が出ます。</div>
          <div>1年間で一番安い価格帯なので買い信号が出たら自己責任で買ってみましょう。</div>
          <div>買ったら緑色の売り信号が出るのを待ってください。</div>
          <div>一列すべてが緑色になったら自分の利益満足で売ってください。</div>
          <div>画面右上の「売信号に切替」ボタンを押すと売り信号のページに切り替わります。また「買信号に切替」ボタンを押すと買い信号のページに切り替わります。</div>
        </>);
        setIsExplanation(data.user.isExTradeSignal7);
        aveCount = 13;
        break;
      default:
        break;
    }
  }


  const handleDateSearch = (dates) => {
    setSearchDateRange(dates);
  };

  const filterDataSourceByDate = () => {
    if (!searchDateRange || searchDateRange.length < 1) {
      setTableDataArray(nikkeiArray);
      return;
    }

    const [start, end] = searchDateRange;
    setTableDataArray( nikkeiArray.filter(item => {
      const itemDate = new Date(item.date);
      (start.$d).setHours(0,0,0,0,0);
      (end.$d).setHours(0,0,0,0,0);
      return itemDate.getTime() >= (start.$d).getTime() && itemDate.getTime() <= (end.$d).getTime();
    }));
  };

  const resetSearchDate = () => {
    setTableDataArray(nikkeiArray);
    setSearchDateRange([]);
  }

  useEffect(() => {
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        setWordWithAveKey(data);
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
    const fetchData = async () => {
      try {
          setIsLoad(true);
          const response = await fetch(process.env.REACT_APP_API_DIR+'/tradeSignal/get', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({aveKey,isBuy}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              setNikkeiArray(data.tradeSignalArray);
              getTodayNikkei(data.tradeSignalArray[0]);
            }
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
      setIsLoad(false);
    };
    fetchData();
  },[isBuy,aveKey]);

  useEffect(() => {
    createColumns();
    resetSearchDate();
  },[nikkeiArray]);

  useEffect(() => {
    createColumns();
  },[searchDateRange]);

  useLayoutEffect(() => {
  
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));      
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });  

  const createColumns = () => {
    const columns = [
      { 
        title: '日付',
        dataIndex: 'date',
        key: 'date',
        fixed: printMode ? '' : 'left',
        filterDropdown: ({ confirm }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={searchDateRange}
              onChange={dates => {
                  handleDateSearch(dates);
              }}
              locale={locale}
            />
            <div style={{ marginTop: 8 }}>
              <Button type="primary" htmlType="submit" onClick={() => {
                filterDataSourceByDate();
                confirm();
              }}>
                検索
              </Button>
              <Button type="default"  className="prev-button" onClick={() => {
                resetSearchDate();
                confirm();
              }}>
                リセット
              </Button>
            </div>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color:"#444"}} className='filter-icon'/>,
        render: date => (
          <>
            <span className='date-pc'>
              {(new Date(date)).getFullYear() + "年" + ((new Date(date).getMonth() + 1)) + "月" + (new Date(date).getDate()) + "日"}
            </span>
            <span className='date-smp'>
            {((new Date(date)).getFullYear()).toString().slice(-2) + "/" + ((new Date(date).getMonth() + 1)) + "/" + (new Date(date).getDate())}
            </span>
          </>
        ),
      },
    ];
    const aveTitleArray = [
      { title: '終値', dataIndex: 'ave1', key: 'ave1' },
    ];
    for(const ave of aveArray){
      aveTitleArray.push({ title: `${ave}日平均`, dataIndex: `ave${ave}`, key: `ave${ave}` });
    }

    for (let i = 0; i < aveTitleArray.length; i++) {
      const average = aveTitleArray[i];
      
      if (i ==0 || (i !=0 && aveKey >= aveArray[i-1])) {
        columns.push({
          ...average,
          render: (ave) => (
            <div style={{ backgroundColor: (ave.rank == i && ave.value !== null) ? (isBuy ? "rgba(255,0,0,0.7)" : "rgba(0,255,0,0.7)") : "" }} className='cell-content'>
              <div>{ave.value}</div>
              <div>({isBuy ? (aveCount - ave.rank) : (ave.rank+1)})【{(ave.rank == i && ave.value !== null) ? <span style={{fontWeight:"bold"}}>{wordArray[i]}</span> : "" }】</div>

            </div>
          )
        });
      }
    }

    setNikkeColumns(columns);
  };

  const printColumns = [
    { title: '日付', dataIndex: 'date', key: 'date' },
    { title: '終値', dataIndex: 'ave1', key: 'ave1' },
  ];
  
  const printArray = [{
    date: '日付',
    ave1: '終値',
  }];
  
  aveArray.forEach(ave => {
    if(ave <=aveKey){
      printColumns.push({ title: `${ave}日平均`, dataIndex: `ave${ave}`, key: `ave${ave}` });
      printArray[0][`ave${ave}`] = `${ave}日平均`;
    }
  });
  
  const tableScroll = printMode ? { x:false, y: false} : { x: true, y: adjustedHeight };
  const pagenation = printMode ? false: { position: ['topRight','bottomRight']};

  useEffect(()=> {
    if(!printMode){
      setTableDataArray(nikkeiArray)
    }
  },[printMode])

  return (
    <>
    <Loading loading={isLoad}/>
    <PrintModal setPrintMode={setPrintMode} setTableDataArray={setTableDataArray} nikkeiArray={nikkeiArray} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
    <ExplanationModal title={modalTitle} columnName={columnName} text={modalText} isExplanation={isExplanation}/>
    {printMode &&
      <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false} />
    }
    <Table dataSource={tableDataArray} columns={nikkeiColumns} bordered scroll={tableScroll} pagination={pagenation} className='ant-table'/>
    </>
  );
};

export default TradeSignalTable;
