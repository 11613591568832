import './App.css';
import {React ,useEffect,useState} from "react";
import { TwelvePsychologyTable,HeaderGroup } from './components';
import PullToRefresh from 'react-simple-pull-to-refresh';

const TwelvePsychology = () => {
  const [printMode, setPrintMode] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handlePrint = () =>{
    setIsPrintModal(true);
  }

  useEffect(() => {
    if(printMode){
        window.print();
    }
  }, [printMode]);
  
  
  useEffect(() => {
    const afterPrint = () => setPrintMode(false);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  return (
    <div className='back-content'>
      <HeaderGroup handlePrint={handlePrint}/>
      <PullToRefresh onRefresh={handleRefresh}>
        <div className='body-content'>
          <div class="signal-label" style={{backgroundColor:"#888",color:"#fff"}}>12日間投資家心理</div>
          <TwelvePsychologyTable printMode={printMode} setPrintMode={setPrintMode} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
        </div>
      </PullToRefresh>
    </div>

  );
};

export default TwelvePsychology;
