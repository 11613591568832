// src/DataTable.js
import './components.css';
import React, { useState, useEffect} from "react";
import { Table, Button} from 'antd';
import { useNavigate } from 'react-router-dom';
import Loading from "./Loading";
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import PrintAllTableModal from './PrintAllTableModal';
dayjs.locale('ja');

const TradeSignalAllTable = (data) => {
  const navigate = useNavigate();
  const { printMode,setPrintMode,isPrintModal,setIsPrintModal } = data;

  const [isLoad,setIsLoad] = useState(false);
  const [signalArray,setSignalArray] = useState([]);

  const [buySale20Array, setBuySale20Array] = useState([]);
  const [buySale30Array, setBuySale30Array] = useState([]);
  const [buySale60Array, setBuySale60Array] = useState([]);
  const [buySale75Array, setBuySale75Array] = useState([]);
  const [buySale90Array, setBuySale90Array] = useState([]);
  const [buySale120Array, setBuySale120Array] = useState([]);
  const [buySale150Array, setBuySale150Array] = useState([]);
  const [moveAveArray, setMoveAveArray] = useState([]);
  const [twelveArray, setTwelveArray] = useState([]);
  const [raitoArray, setRaitoArray] = useState([]);


  useEffect(() => {
    const fetchData = async (endPoint) => {
      try {
          const response = await fetch(process.env.REACT_APP_API_DIR+`${endPoint}`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              return data.signalArray;
            }
          }
      } catch (error) {
          console.error('Fetch error:', error);
          return [];
      }

      
    };
    
    setIsLoad(true);
    let tmpArray = [];
    fetchData('/nikkeiStock/get/all').then((nikkeiArray)=>{
      tmpArray = [...nikkeiArray];
    }).catch((e)=>{
      console.log(e)
    }).finally(()=>{
      fetchData('/individualStock/get/all').then((individualArray)=>{
        tmpArray = [...tmpArray,...individualArray];
        setSignalArray(tmpArray);
      }).catch((e)=>{
        console.log(e)
      }).finally(()=>{
        setIsLoad(false);
      });
    });

  },[]);

  useEffect(() => {
    setBuySale20Array(signalArray.filter(item => item.key === "buy_20" || item.key === "sale_20"));
    setBuySale30Array(signalArray.filter(item => item.key === "buy_30" || item.key === "sale_30"));
    setBuySale60Array(signalArray.filter(item => item.key === "buy_60" || item.key === "sale_60"));
    setBuySale75Array(signalArray.filter(item => item.key === "buy_75" || item.key === "sale_75"));
    setBuySale90Array(signalArray.filter(item => item.key === "buy_90" || item.key === "sale_90"));
    setBuySale120Array(signalArray.filter(item => item.key === "buy_120" || item.key === "sale_120"));
    setBuySale150Array(signalArray.filter(item => item.key === "buy_150" || item.key === "sale_150"));
    setMoveAveArray(signalArray.filter(item => item.key === "buy_move_ave" || item.key === "sale_move_ave"));
    setTwelveArray(signalArray.filter(item => item.key === "twelve_up" || item.key === "twelve_down"));
    setRaitoArray(signalArray.filter(item => item.key === "ratio_up" || item.key === "ratio_down"));
  },[signalArray]);

  const handleIndividualStock = (stockCode,key) =>{
    if(key === "ave_20") navigate(`/IndividualStock/TradeSignal?ave=20&stock=${stockCode}`);
    if(key === "ave_30") navigate(`/IndividualStock/TradeSignal?ave=30&stock=${stockCode}`);
    if(key === "ave_60") navigate(`/IndividualStock/TradeSignal?ave=60&stock=${stockCode}`);
    if(key === "ave_75") navigate(`/IndividualStock/TradeSignal?ave=75&stock=${stockCode}`);
    if(key === "ave_90") navigate(`/IndividualStock/TradeSignal?ave=90&stock=${stockCode}`);
    if(key === "ave_120") navigate(`/IndividualStock/TradeSignal?ave=120&stock=${stockCode}`);
    if(key === "ave_150") navigate(`/IndividualStock/TradeSignal?ave=150&stock=${stockCode}`);
    if(key === "move_ave") navigate(`/IndividualStock/TradeSignalWRank?stock=${stockCode}`);
    if(key === "twelve") navigate(`/IndividualStock/TwelvePsychology?stock=${stockCode}`);
    
  }


const columnsArray = [
  {key:"ave_20", columns:getColumns("ave_20")},
  {key:"ave_30", columns:getColumns("ave_30")},
  {key:"ave_60", columns:getColumns("ave_60")},
  {key:"ave_75", columns:getColumns("ave_75")},
  {key:"ave_90", columns:getColumns("ave_90")},
  {key:"ave_120", columns:getColumns("ave_120")},
  {key:"ave_150", columns:getColumns("ave_150")},
  {key:"ave_150", columns:getColumns("ave_150")},
  {key:"move_ave", columns:getColumns("move_ave")},
  {key:"twelve", columns:getColumns("twelve")},
  {key:"ratio", columns:getColumns("ratio")}
];


function getColumns(key){
  return [
    { title: '日付', dataIndex: 'date', key: 'date',
      render: date => (
        <>
          <span className='date-pc'>
            {(new Date(date)).getFullYear() + "年" + ((new Date(date).getMonth() + 1)) + "月" + (new Date(date).getDate()) + "日"}
          </span>
          <span className='date-smp'>
          {((new Date(date)).getFullYear()).toString().slice(-2) + "/" + ((new Date(date).getMonth() + 1)) + "/" + (new Date(date).getDate())}
          </span>
        </>
    ),
    },
    { title: '銘柄コード', dataIndex: ['individualObj', 'stockCode'], key: 'stockCode',
      render: stockCode => (
        <>
          {(stockCode === "") ? (
            <>{stockCode}</>
          ) : (
            <Button  onClick={() => handleIndividualStock(stockCode,key)}>{stockCode}</Button>
          )}
         
        </>
      ),
    },
    { title: '銘柄名', dataIndex: ['individualObj', 'stockName'], key: 'stockName'},
    { title: '業種名', dataIndex: ['individualObj', 'industryName'], key: 'industryName'},
    { title: '終値', dataIndex: 'closePrice', key: 'closePrice' ,
      render: data => (
        <span style={{color:"red",fontWeight:"bold"}}>
          {data}
        </span>
      ),
    },
    { title: '52週高値', dataIndex: ['individualObj', 'yearlyHighStock'], key: 'yearlyHighStock'},
    { title: '52週安値', dataIndex: ['individualObj', 'yearlyLowStock'], key: 'yearlyLowStock'},
    { title: '年初来高値', dataIndex: ['individualObj', 'YTDHighStock'], key: 'YTDHighStock'},
    { title: '年初来安値', dataIndex: ['individualObj', 'YTDLowStock'], key: 'YTDLowStock'},
    { title: '信号', dataIndex: 'signal', key: 'signal' ,
      render: (signal) => (
        <div style={{ backgroundColor: signal.isBuy? "rgba(255,0,0,0.7)" : "rgba(0,255,0,0.7)"}} className='cell-content-all'>
          <div>{signal.text}</div>
        </div>
      )
    },
  ];
}

const printColumns = [
  { title: '日付', dataIndex: 'date', key: 'date' },
  { title: '銘柄コード', dataIndex: 'stockCode', key: 'stockCode'},
  { title: '銘柄名', dataIndex: 'stockName', key: 'stockName'},
  { title: '業種名', dataIndex: 'industryName', key: 'industryName'},
  { title: '終値', dataIndex: 'closePrice', key: 'closePrice' },
  { title: '52週高値', dataIndex: 'yearlyHighStock', key: 'yearlyHighStock'},
  { title: '52週安値', dataIndex: 'yearlyLowStock', key: 'yearlyLowStock'},
  { title: '年初来高値', dataIndex: 'YTDHighStock', key: 'YTDHighStock'},
  { title: '年初来安値', dataIndex: 'YTDLowStock', key: 'YTDLowStock'},
  { title: '信号', dataIndex: 'signal', key: 'signal'}
];

const printArray = [{
  date: '日付',
  stockCode: '銘柄コード',
  stockName:'銘柄名',
  industryName:'業種名',
  closePrice:'終値',
  yearlyHighStock:'52週高値',
  yearlyLowStock:'52週安値',
  YTDHighStock:'年初来高値',
  YTDLowStock:'年初来安値',
  signal:'信号'
}];

const tableScroll = printMode ? { x:false, y: false} : { x: true, y: "200px" };
const pagenation = printMode ? false: { position: ['bottomRight']};


  return (
    <>
      <Loading loading={isLoad}/>
      <PrintAllTableModal setPrintMode={setPrintMode} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
      <div className='signal-content ave20-content'>
        <div className='signal-title'>売買信号(20日間)</div>
        {buySale20Array && buySale20Array.length > 0 ? (
          <>
            <div style={{marginLeft:"20px"}}>売買信号が点灯しました、過去の値動きを見て自己判断で売買してください</div>
            {printMode &&
              <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
            }
            <Table dataSource={buySale20Array} columns={(columnsArray.find(item => item.key === "ave_20")).columns} bordered scroll={tableScroll} pagination={pagenation}  />
          </>
        ) : (
          <div className='signal-no-text'>本日売買信号はでていません</div>
        )}
      </div>

      <div className='signal-content ave30-content'>
        <div className='signal-title'>売買信号(30日間)</div>
        {buySale30Array && buySale30Array.length > 0 ? (
          <>
            <div style={{marginLeft:"20px"}}>売買信号が点灯しました、過去の値動きを見て自己判断で売買してください</div>
            {printMode &&
              <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
            }
            <Table dataSource={buySale30Array} columns={(columnsArray.find(item => item.key === "ave_30")).columns} bordered scroll={tableScroll} pagination={pagenation}  />
          </>
        ) : (
          <div className='signal-no-text'>本日売買信号はでていません</div>
        )}
      </div>
      <div className='signal-content ave60-content'>
        <div className='signal-title'>売買信号(60日間)</div>
        {buySale60Array && buySale60Array.length > 0 ? (
          <>
            <div style={{marginLeft:"20px"}}>売買信号が点灯しました、過去の値動きを見て自己判断で売買してください</div>
            {printMode &&
              <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
            }
            <Table dataSource={buySale60Array} columns={(columnsArray.find(item => item.key === "ave_60")).columns} bordered scroll={tableScroll} pagination={pagenation}  />
          </>
        ) : (
          <div className='signal-no-text'>本日売買信号はでていません</div>
        )}
      </div>
      <div className='signal-content ave75-content'>
        <div className='signal-title'>売買信号(75日間)</div>
        {buySale75Array && buySale75Array.length > 0 ? (
          <>
            <div style={{marginLeft:"20px"}}>売買信号が点灯しました、過去の値動きを見て自己判断で売買してください</div>
            {printMode &&
              <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
            }
            <Table dataSource={buySale75Array} columns={(columnsArray.find(item => item.key === "ave_75")).columns} bordered scroll={tableScroll} pagination={pagenation}  />
          </>
        ) : (
          <div className='signal-no-text'>本日売買信号はでていません</div>
        )}
      </div>
      <div className='signal-content ave90-content'>
        <div className='signal-title'>売買信号(90日間)</div>
        {buySale90Array && buySale90Array.length > 0 ? (
          <>
            <div style={{marginLeft:"20px"}}>売買信号が点灯しました、過去の値動きを見て自己判断で売買してください</div>
            {printMode &&
              <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
            }
            <Table dataSource={buySale90Array} columns={(columnsArray.find(item => item.key === "ave_90")).columns} bordered scroll={tableScroll} pagination={pagenation}  />
          </>
        ) : (
          <div className='signal-no-text'>本日売買信号はでていません</div>
        )}
      </div>
      <div className='signal-content ave120-content'>
        <div className='signal-title'>売買信号(120日間)</div>
        {buySale120Array && buySale120Array.length > 0 ? (
          <>
            <div style={{marginLeft:"20px"}}>売買信号が点灯しました、過去の値動きを見て自己判断で売買してください</div>
            {printMode &&
              <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
            }
            <Table dataSource={buySale120Array} columns={(columnsArray.find(item => item.key === "ave_120")).columns} bordered scroll={tableScroll} pagination={pagenation}  />
          </>
        ) : (
          <div className='signal-no-text'>本日売買信号はでていません</div>
        )}
      </div>
      <div className='signal-content ave150-content'>
        <div className='signal-title'>売買信号(150日間)</div>
        {buySale150Array && buySale150Array.length > 0 ? (
          <>
            <div style={{marginLeft:"20px"}}>売買信号が点灯しました、過去の値動きを見て自己判断で売買してください</div>
            {printMode &&
              <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
            }
            <Table dataSource={buySale150Array} columns={(columnsArray.find(item => item.key === "ave_150")).columns} bordered scroll={tableScroll} pagination={pagenation}  />
          </>
        ) : (
          <div className='signal-no-text'>本日売買信号はでていません</div>
        )}
      </div>
      <div className='signal-content aveRank-content'>
        <div className='signal-title'>移動平均線順位信号</div>
        {moveAveArray && moveAveArray.length > 0 ? (
          <>
            <div style={{marginLeft:"20px"}}>売買信号が点灯しました、過去の値動きを見て自己判断で売買してください</div>
            {printMode &&
              <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
            }
            <Table dataSource={moveAveArray} columns={(columnsArray.find(item => item.key === "move_ave")).columns} bordered scroll={tableScroll} pagination={pagenation}  />
          </>
        ) : (
          <div className='signal-no-text'>本日売買信号はでていません</div>
        )}
      </div>
      <div className='signal-content twelve-content'>
        <div className='signal-title'>12日間投資家心理</div>
        {twelveArray && twelveArray.length > 0 ? (
          <>
            <div style={{marginLeft:"20px"}}>12日間の心理的意識信号が出ました、自己判断で売買してください</div>
            {printMode &&
              <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
            }
            <Table dataSource={twelveArray} columns={(columnsArray.find(item => item.key === "twelve")).columns} bordered scroll={tableScroll} pagination={pagenation}  />
          </>
          
        ) : (
          <div className='signal-no-text'>本日売買信号はでていません</div>
        )}
      </div>
      <div className='signal-content ratio-content'>
        <div className='signal-title'>25日騰落レシオ</div>
        {raitoArray && raitoArray.length > 0 ? (
          <>
            <div style={{marginLeft:"20px"}}>25日間の過熱感や強弱感の信号が出ました、自己判断で売買してください</div>
            {printMode &&
              <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
            }
            <Table dataSource={raitoArray} columns={(columnsArray.find(item => item.key === "ratio")).columns} bordered scroll={tableScroll} pagination={pagenation} />
          </>
        ) : (
          <div className='signal-no-text'>本日売買信号はでていません</div>
        )}
      </div>
    </>
   
  );
};

export default TradeSignalAllTable;
