import './App.css';
import React, { useState } from "react";
import { Form, Input, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const PasswordReset = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const tapPrev = () => {
    navigate("/");
  };
  const onSubmit = (values) => {
    const fetchData = async () => {
      try {
          const response = await fetch(process.env.REACT_APP_API_DIR+'/password/reset', {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({mail:values.email}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              setError(data.errorText);
            }
            else{
              console.log(data.errorText);
              alert(data.errorText);
              navigate("/");
            }
          }
      } catch (error) {
          setError("ネットワークエラーです");
          console.error('Fetch error:', error);
      }
    };
    fetchData();
  };

  return (
    <div className='back-content'>
      <div className='main-content'>

        <Form
          name="normal_login"
          className="common-form"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
        >
        <h1 className='form-title'>パスワードリセット</h1>
        <p style={{ fontSize: '90%' }}>本アプリに登録しているメールアドレスを入力してください。入力したメールアドレス宛に新しいパスワードが送信されます。</p>
        <p style={{ fontSize: '90%' }}>※本メールが届かない場合は迷惑メールフォルダをご確認ください。</p>
        {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'メールアドレスを入力してください'}]}
            label={<span style={{fontSize:"120%"}}>メールアドレス</span>}
            labelCol={{span:24}}
            
          >
            <Input prefix={<MailOutlined />}
              style={{fontSize:"120%"}}
            />
          </Form.Item>

          <Form.Item>
            <Button type="default"  className="prev-button" onClick={tapPrev}>
              戻る
            </Button>
            <Button type="primary" htmlType="submit" className="login-form-button">
              送信
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>

  );
};

export default PasswordReset;
