// src/DataTable.js
import './components.css';
import {Modal,Button,Select,Form} from 'antd';
import React, { useEffect ,useRef} from "react";
import dayjs from 'dayjs';
const {Option} = Select

dayjs.locale('ja');


const PrintAllTableModal = ({setPrintMode,isPrintModal,setIsPrintModal}) => {

  const isInitialMount = useRef(true);
  const isCloseMount = useRef(false);

  const classes = [
    'ave20-content',
    'ave30-content',
    'ave60-content',
    'ave75-content',
    'ave90-content',
    'ave120-content',
    'ave150-content',
    'aveRank-content',
    'twelve-content',
    'ratio-content'
  ];

  const handleClose = () =>{
    setIsPrintModal(false)
    isCloseMount.current = true
  }


  const startPrint = (values) => {
    if(values.signal !== 'all'){
      classes.forEach((className) => {
        const element = document.querySelector(`.${className}`);
        if (element) {
          if (className !==values.signal) {
            element.classList.add('hidden');
          } 
        }
      });
    }
    setIsPrintModal(false)
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false; // 初回ロード時にはフラグをfalseに設定
    } 
    else {
      if (!isPrintModal && !isCloseMount.current) {
        setTimeout(() => {
          setPrintMode(true);
        }, 200);
      }
    }
    if(isCloseMount.current){
      isCloseMount.current = false
    }
  }, [isPrintModal]);


  return (
    <Modal
      title="印刷する売買信号を選択してください"
      open={isPrintModal}
      onCancel={() => handleClose()}
      footer={[
          <>
            <div style={{ textAlign:"start" }}>
              <Form
                onFinish={startPrint}
              >
                <Form.Item
                  name="signal"
                  rules={[{ required: true, message: '売買信号を選択してください' }]}
                  initialValue="all"
                >
                  <Select
                    style={{fontSize:"120%", width: '100%'}}
                  >
                    <Option value="all"><span style={{fontSize:"120%"}}>すべて</span></Option>
                    <Option value="ave20-content"><span style={{fontSize:"120%"}}>売買信号(20日間)</span></Option>
                    <Option value="ave30-content"><span style={{fontSize:"120%"}}>売買信号(30日間)</span></Option>
                    <Option value="ave60-content"><span style={{fontSize:"120%"}}>売買信号(60日間)</span></Option>
                    <Option value="ave75-content"><span style={{fontSize:"120%"}}>売買信号(75日間)</span></Option>
                    <Option value="ave90-content"><span style={{fontSize:"120%"}}>売買信号(90日間)</span></Option>
                    <Option value="ave120-content"><span style={{fontSize:"120%"}}>売買信号(120日間)</span></Option>
                    <Option value="ave150-content"><span style={{fontSize:"120%"}}>売買信号(150日間)</span></Option>
                    <Option value="aveRank-content"><span style={{fontSize:"120%"}}>移動平均線順位信号</span></Option>
                    <Option value="twelve-content"><span style={{fontSize:"120%"}}>12日間投資家心理</span></Option>
                    <Option value="ratio-content"><span style={{fontSize:"120%"}}>25日騰落レシオ</span></Option>
                  </Select>
                </Form.Item>

                <div style={{margin:"20px 0"}}>
                  <Button type="primary" htmlType="submit" style={{margin:"0 5px"}}>
                      印刷
                  </Button>
                  <Button style={{margin:"0 5px"}} onClick={handleClose}>
                      閉じる
                  </Button>
                </div>
                
              </Form>
            </div>
          </>
      ]}
    >
    </Modal>
  );
};

export default PrintAllTableModal;
