import '../App.css';
import React, { useState } from "react";
import { MyHoldingsTable,HeaderGroup } from './components';
import { useNavigate,useLocation } from 'react-router-dom';
import { Button } from 'antd';

const MyHoldings = () => {
  const [totalProfitLoss,setTotalProfitLoss] = useState(0)
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const handleInsertPage = () => {
    navigate(`/IndividualStock/InsertMyHoldings?stock=${queryParams.get('stock')}`);
  }

  return (
    <div className='back-content'>
      <HeaderGroup/>
      <div className='body-content'>
        <div class="signal-label" style={{backgroundColor:"#888",color:"#fff"}}>持ち株一覧</div>
        <div style={{display:"flex",justifyContent:"space-between"}}>
          <div style={{margin:"5px",fontWeight:"bold"}}>合計損益：{totalProfitLoss > 0 ? (<span style={{color:"green"}}>{totalProfitLoss.toLocaleString()}</span>) : (<span style={{color:"red"}}>{totalProfitLoss.toLocaleString()}</span>)}円</div>
          <div style={{margin:"5px"}}><Button onClick={e => handleInsertPage()}>持ち株登録</Button></div>
        </div>
        <MyHoldingsTable setTotalProfitLoss={setTotalProfitLoss}/>
      </div>
    </div>

  );
};

export default MyHoldings;
