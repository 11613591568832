// src/DataTable.js
import './components.css';
import { UserOutlined,PrinterOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { loginCheck,getUserInfo} from '../commonFunc';
import { useNavigate,useLocation } from 'react-router-dom';
import Burger from "./Burger/Burger"

const TopHeader = ({open,setOpen,handlePrint}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuto, setIsAuto] = useState(false);
  const [isIndividualStock, setIsIndividualStock] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const currentURL = location.pathname;
  useEffect(() => {
    loginCheck().then(data =>{
      if(!data){
        navigate("/");
      }
    }).catch(err=>{
      navigate("/");
    });
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        setIsAdmin(data.user.isAdmin);
        setIsAuto(data.user.isAuto);
        setIsIndividualStock(data.user.isIndividualStock);
        if((currentURL === "/UsersList" || currentURL === "/Userslist" || currentURL === "/InsertUserInfo") && !data.user.isAdmin){
          navigate("/");
        }
        if(currentURL === "/InsertNikkeiStockManual" && data.user.isAuto){
          navigate("/");
        }
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
  },[]);

  useEffect(()=> {
    if(currentURL === "/NikkeiStock" || 
      currentURL === "/TradeSignal" ||  
      currentURL === "/NikkeiStockFeature" || 
      currentURL === "/TradeSignalW" || 
      currentURL === "/TradeSignalWRank" || 
      currentURL === "/TradeSignalWAveDiff" || 
      currentURL === "/TwelvePsychology" ||
      currentURL === "/upDownRatio"
    ){
      setIsDisable(false);
    }
    else{
      setIsDisable(true);
    }
  },[currentURL])

  const handleLogout = () => {
    const fetchData = async () => {
      try {
          const response = await fetch(process.env.REACT_APP_API_DIR+'/login/logout', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              alert(data.errorText);
            }
            else{
              alert(data.errorText);
              navigate("/");
            }
          }
      } catch (error) {
          alert("ネットワークエラーです");
          console.error('Fetch error:', error);
      }
    };
    fetchData();
  };


  const menu = (
    <Menu>
      <Menu.Item key="1"><Link to="/MyPage">マイページ</Link></Menu.Item>
      { isAdmin ? (<Menu.Item key="2"><Link to="/UsersList">ユーザー一覧</Link></Menu.Item>) : (<></>) }
      { isAdmin ? (<Menu.Item key="3"><Link to="/InsertUserInfo">新規ユーザー登録</Link></Menu.Item>) : (<></>) }
      { !isAuto ? (<Menu.Item key="4"><Link to="/InsertNikkeiStockManual">日経平均株価の手動入力</Link></Menu.Item>) : (<></>) }
      { isIndividualStock ? (<Menu.Item key="5"><Link to="/IndividualStock/TradeSignalAll?stock=4151">個別株ページに切り替える</Link></Menu.Item>) : (<></>) }
      <Menu.Item key="6"><Link to="/Manual">マニュアル</Link></Menu.Item>
      <Menu.Item key="7"><Link onClick={handleLogout}>ログアウト</Link></Menu.Item>
    </Menu>
  );

  return (
    <div className='top-header'>
      <div className='burger-btn'><Burger open={open} setOpen={setOpen} /></div>
      <div className='app-title'>株式天照ソフト(アマテラス)</div>
      <div style={{display:"flex",justifyContent:"center",verticalAlign:"middle"}}>
        <div style={{margin:"auto 3px"}} className='print-btn'><Button icon={<PrinterOutlined />} onClick={e =>  handlePrint()} disabled={isDisable}></Button></div>
        <div style={{margin:"auto 3px"}}>
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            <UserOutlined style={{ fontSize: '30px', color: 'white',paddingRight:"15px",cursor:"pointer"}} />
          </Dropdown> 
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
