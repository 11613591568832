import './components.css';
import React, { useState} from "react";

const TradeSimurationTable = () => {
  const [confirmStock,setConfirmStock] = useState(null);
  const [tradeStock,setTradeStock] = useState(null);
  const [stockNum,setStockNum] = useState(0);
  const [nowStock,setNowStock] = useState(null);
  const [yearStock,setYearStock] = useState(null);
  const [featureStock2,setFeatureStock2] = useState(null);
  const [featureStock3,setFeatureStock3] = useState(null);
  const [featureStock4,setFeatureStock4] = useState(null);
  const [stockCode,setStockCode] = useState(null);
  const [stockName,setStockName] = useState(null);
  return (
    <>
      <div style={{margin:"15px 0"}}>
        <div>
          <table style={{display:"flex",justifyContent:"flex-end",marginBottom:"10px"}}>
            <tr>
              <td style={{border:"1px #000 solid"}}><input type='number' value={stockCode} placeholder='銘柄コード' onChange={e=> setStockCode(e.target.value)}/></td>
              <td style={{border:"1px #000 solid"}}><input type='text' value={stockName} placeholder='銘柄名' onChange={e=> setStockName(e.target.value)}/></td>
            </tr>
          </table>
        </div>
        <div>売買シミュレーション計算機</div>
        <table>
          <tr>
            <td style={{border:"1px #000 solid"}}>満足売値</td>
            <td style={{border:"1px #000 solid"}}><input type='number' value={confirmStock} placeholder='売りたい価格を入力' onChange={e=> setConfirmStock(e.target.value)}/></td>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>買値</td>
            <td style={{border:"1px #000 solid"}}><input type='number' value={tradeStock} placeholder='買いたい価格を入力' onChange={e=> setTradeStock(e.target.value)}/></td>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>株数</td>
            <td style={{border:"1px #000 solid"}}><input type='number' value={stockNum} placeholder='株数を入力' onChange={e=> setStockNum(e.target.value)}/></td>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>利益</td>
            <td style={{border:"1px #000 solid"}}>{confirmStock-tradeStock}</td>
            <span>　一株当たりの利益</span>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>利益率</td>
            <td style={{border:"1px #000 solid"}}>{confirmStock !== null && tradeStock !== null ? ((confirmStock-tradeStock)/tradeStock*100).toFixed(2) : 0}%</td>
            <span>　売買時の判断基準</span>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>利益</td>
            <td style={{border:"1px #000 solid"}}>{(confirmStock-tradeStock)*stockNum}円</td>
            <span>　利益＝満足売値</span>
          </tr>
        </table>
      </div>
      <div style={{margin:"15px 0"}}>
        <div>52週高値価格からの下落計算機</div>
        <table>
          <tr>
            <td style={{border:"1px #000 solid"}}>52週高値</td>
            <td style={{border:"1px #000 solid"}}><input type='number' value={yearStock} placeholder='52週高値を入力' onChange={e=> setYearStock(e.target.value)}/></td>
            <span>　売買信号ページを参照</span>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>現在株価</td>
            <td style={{border:"1px #000 solid"}}><input type='number' value={nowStock} placeholder='現在株価を入力' onChange={e=> setNowStock(e.target.value)}/></td>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>下落幅</td>
            <td style={{border:"1px #000 solid"}}>{nowStock-yearStock}</td>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>下落率</td>
            <td style={{border:"1px #000 solid"}}>{nowStock !== null && yearStock !== null ? ((nowStock-yearStock)/yearStock*100).toFixed(2) : 0}%</td>
          </tr>
        </table>
        <div>一年間の高値より{nowStock !== null && yearStock !== null ? ((nowStock-yearStock)/yearStock*100).toFixed(2) : 0}%下落しています</div>
        <div>　</div>
        <table>
          <tr>
            <td style={{border:"1px #000 solid"}}></td>
            <td style={{border:"1px #000 solid"}}>売買希望価格</td>
            <td style={{border:"1px #000 solid"}}>現在株価との差</td>
            <td style={{border:"1px #000 solid"}}>一株当たり利益</td>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>上昇抵抗線1</td>
            <td style={{border:"1px #000 solid"}}><input type='number' value={featureStock2} placeholder='売買希望価格を入力' onChange={e=> setFeatureStock2(e.target.value)}/></td>
            <td style={{border:"1px #000 solid"}}>{featureStock2 - nowStock}</td>
            <td style={{border:"1px #000 solid"}}>{featureStock2 !== null && nowStock !== null ? ((featureStock2 - nowStock)/nowStock*100).toFixed(2) : 0}%</td>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>上昇抵抗線2</td>
            <td style={{border:"1px #000 solid"}}><input type='number' value={featureStock3} placeholder='売買希望価格を入力' onChange={e=> setFeatureStock3(e.target.value)}/></td>
            <td style={{border:"1px #000 solid"}}>{featureStock3 - nowStock}</td>
            <td style={{border:"1px #000 solid"}}>{featureStock3 !== null && nowStock !== null ? ((featureStock3 - nowStock)/nowStock*100).toFixed(2) : 0}%</td>
          </tr>
          <tr>
            <td style={{border:"1px #000 solid"}}>上昇抵抗線3</td>
            <td style={{border:"1px #000 solid"}}><input type='number' value={featureStock4} placeholder='売買希望価格を入力' onChange={e=> setFeatureStock4(e.target.value)}/></td>
            <td style={{border:"1px #000 solid"}}>{featureStock4 - nowStock}</td>
            <td style={{border:"1px #000 solid"}}>{featureStock4 !== null && nowStock !== null ? ((featureStock4 - nowStock)/nowStock*100).toFixed(2) : 0}%</td>
          </tr>
        </table>
      </div>
    </>

  );
};

export default TradeSimurationTable;
