import styled from 'styled-components';

export const StyledMenu = styled.nav`
.side-navigation{
  transition: transform 0.3s ease-in-out;
  @media (max-width: 1024px) {
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'}; 
  }
}



  
`;
