const getIndividualStock = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/individualStock/get', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        throw error;

    }
}

export {getIndividualStock};