import './App.css';
import React, { useState, useEffect } from "react";
import { Form, Input, Button } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import { HeaderGroup } from './components';
import { useNavigate } from 'react-router-dom';

const InsertNikkeiStockManual = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const onSubmit = (values) => {
    const fetchData = async () => {
      try {
          const response = await fetch(process.env.REACT_APP_API_DIR+'/nikkeiStock/insert', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              openPrice:values.openPrice,
              highPrice:values.highPrice,
              lowPrice:values.lowPrice,
              closePrice:values.closePrice,
              beforeDayDiff:values.beforeDayDiff,
              beforeDayRatio:values.beforeDayRatio,
              date:values.date
            }),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              setError(data.errorText);
            }
            else{
              console.log(data.errorText);
              alert(data.errorText);
              navigate("/MyPage");
            }
          }
      } catch (error) {
          setError("ネットワークエラーです");
          console.error('Fetch error:', error);
      }
    };
    fetchData();
  };


  const tapPrev = () => {
    navigate("/MyPage");
  };

  const getDateFormat=(date)=>{
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    return `${localDate.getFullYear()}-${(localDate.getMonth() + 1).toString().padStart(2, '0')}-${localDate.getDate().toString().padStart(2, '0')}`;
  }

  return (
    <div className='back-content'>
      <div className='main-content'>
        <HeaderGroup/>
        <div className='body-content'>
        <h2 className='page-title'>日経平均株価の手動入力</h2>
          <div className='page-content'>
            
          <Form
            name="password_change"
            className="page-form"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
          >
            <div style={{margin:"10px 0",fontSize:"110%"}}>
              <a href='https://kabutan.jp/stock/kabuka?code=0000&ashi=day&page=1' target="_blank">
              こちら</a>
              から日経平均株価を確認し、入力してください。
            </div>
            {error && <p style={{ color: 'red', fontSize: '80%' }}>{error}</p>}
            <Form.Item
              name="date"
              rules={[{ required: true, message: '今日の日付を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>今日の日付</span>}
              labelCol={{span:24}}
            >
              <Input prefix={<DatabaseOutlined />}
                style={{fontSize:"120%"}}
                type='date'
              />
            </Form.Item>
            <Form.Item
              name="openPrice"
              rules={[{ required: true, message: '始値を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>始値</span>}
              labelCol={{span:24}}
            >
              <Input
                style={{fontSize:"120%"}}
                type="number"
              />
            </Form.Item>
            <Form.Item
              name="highPrice"
              rules={[{ required: true, message: '高値を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>高値</span>}
              labelCol={{span:24}}
            >
              <Input
                style={{fontSize:"120%"}}
                type="number"
              />
            </Form.Item>
            <Form.Item
              name="lowPrice"
              rules={[{ required: true, message: '安値を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>安値</span>}
              labelCol={{span:24}}
            >
              <Input
                style={{fontSize:"120%"}}
                type="number"
              />
            </Form.Item>
            <Form.Item
              name="closePrice"
              rules={[{ required: true, message: '終値を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>終値</span>}
              labelCol={{span:24}}
            >
              <Input
                style={{fontSize:"120%"}}
                type="number"
              />
            </Form.Item>
            <Form.Item
              name="beforeDayDiff"
              rules={[{ required: true, message: '前日比を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>前日比</span>}
              labelCol={{span:24}}
            >
              <Input
                style={{fontSize:"120%"}}
                type="number"
              />
            </Form.Item>
            <Form.Item
              name="beforeDayRatio"
              rules={[{ required: true, message: '前日比%を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>前日比%</span>}
              labelCol={{span:24}}
            >
              <Input
                style={{fontSize:"120%"}}
                type="number"
              />
            </Form.Item>
            <Form.Item>
              <Button type="default"  className="prev-button" onClick={tapPrev}>
                戻る
              </Button>
              <Button type="primary" htmlType="submit" className="login-form-button">
                登録
              </Button>
            </Form.Item>
          </Form>
          </div>
        </div>
      </div>
    </div>

  );
};

export default InsertNikkeiStockManual;
