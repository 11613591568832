// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useLayoutEffect} from "react";
import { getUserInfo} from '../commonFunc';
import { Table,DatePicker, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Loading from "./Loading";
import { SearchOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import ExplanationModal from './ExplanationModal';
import PrintModal from './PrintModal';

dayjs.locale('ja');

const { RangePicker } = DatePicker;

const NikkeiStockTable = (data) => {
  const { printMode,setPrintMode,isPrintModal,setIsPrintModal } = data;

  const [adjustedHeight, setAdjustedHeight] = useState(0);

  const [nikkeiArray,setNikkeiArray] = useState([]);
  const [nikkeiArrayManual,setNikkeiArrayManual] = useState([]);
  const [isAuto,setIsAuto] = useState(true);
  const [manualChangeDate,setManualChangeDate]= useState(null);
  const [isLoad,setIsLoad] = useState(false);
  const navigate = useNavigate();
  
  const [tableDataArray,setTableDataArray] = useState(null);
  const [searchDateRange, setSearchDateRange] = useState([]);

  const [isExplanation,setIsExplanation] = useState(false);

  const handleDateSearch = (dates) => {
    setSearchDateRange(dates);
  };

  const filterDataSourceByDate = (data) => {
    if (!searchDateRange || searchDateRange.length < 1) {
      setTableDataArray(data);
      return;
    }

    const [start, end] = searchDateRange;
    setTableDataArray( data.filter(item => {
      const itemDate = new Date(item.date);
      (start.$d).setHours(0,0,0,0,0);
      (end.$d).setHours(0,0,0,0,0);
      return itemDate.getTime() >= (start.$d).getTime() && itemDate.getTime() <= (end.$d).getTime();
    }));
  };

  const resetSearchDate = () => {
    setTableDataArray(nikkeiArray);
    setSearchDateRange([]);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoad(true);
          const response = await fetch(process.env.REACT_APP_API_DIR+'/nikkeiStock/get', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              setNikkeiArray(data.nikkeiArray);
            }
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
      setIsLoad(false);
    };
    fetchData();
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        setIsAuto(data.user.isAuto);
        setIsExplanation(data.user.isExNikkeiStock);
        setManualChangeDate(data.user.manualChangeDate);
        if(!data.user.isAuto){
          getManual();
        }
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });

  },[]);

  useEffect(() => {
    resetSearchDate();
  },[nikkeiArray]);

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const columns = [
    { 
      title: '日付',
      dataIndex: 'date',
      key: 'date',
      fixed: printMode ? '' : 'left',
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            value={searchDateRange}
            onChange={dates => {
                handleDateSearch(dates);
            }}
            locale={locale}
          />
          <div style={{ marginTop: 8 }}>
            <Button type="primary" htmlType="submit" onClick={() => {
              filterDataSourceByDate(nikkeiArray);
              confirm();
            }}>
              検索
            </Button>
            <Button type="default"  className="prev-button" onClick={() => {
              resetSearchDate();
              confirm();
            }}>
              リセット
            </Button>
          </div>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color:"#444"}} className='filter-icon'/>,
      render: date => (
        <>
          <span className='date-pc'>
            {(new Date(date)).getFullYear() + "年" + ((new Date(date).getMonth() + 1)) + "月" + (new Date(date).getDate()) + "日"}
          </span>
          <span className='date-smp'>
          {((new Date(date)).getFullYear()).toString().slice(-2) + "/" + ((new Date(date).getMonth() + 1)) + "/" + (new Date(date).getDate())}
          </span>
        </>
      ),
    },
    { title: '始値', dataIndex: 'openPrice', key: 'openPrice' },
    { title: '高値', dataIndex: 'highPrice', key: 'highPrice' },
    { title: '安値', dataIndex: 'lowPrice', key: 'lowPrice' },
    { title: '終値', dataIndex: 'closePrice', key: 'closePrice' },
    { title: '前日比', dataIndex: 'beforeDayDiff', key: 'beforeDayDiff',
        render: beforeDayDiff => (
            <>
            {beforeDayDiff > 0 ? (
                <span style={{color:"green"}}>
                +{beforeDayDiff}
                </span>
            ) : (
              <>
                {beforeDayDiff === 0 ? (
                  <span style={{color:"black"}}>
                  {beforeDayDiff}
                  </span>
                ) : (
                  <span style={{color:"red"}}>
                  {beforeDayDiff}
                  </span>
                )}
              </>
            )}
            </>
        ),
    },
    { title: '前日比%', dataIndex: 'beforeDayRatio', key: 'beforeDayRatio',
        render: (beforeDayRatio)=> {
          if(beforeDayRatio > 0) {
            return (
              <span style={{color:"green"}}>
                +{beforeDayRatio}
              </span>
            );
          }
          else if(beforeDayRatio === 0){
            return (
              <span style={{color:"black"}}>
                {beforeDayRatio}
              </span>
            );

          }   
          else{
            return (
              <span style={{color:"red"}}>
                {beforeDayRatio}
              </span>
            );
  
          }
      }         
    },
  ];

  if (!isAuto && !printMode) {
    columns.push({ 
      title: '手動入力', dataIndex: 'date', key: 'date' ,
      render: date => (
        <>

          {(new Date(manualChangeDate)).getTime() > (new Date(date)).getTime() ?
            (
              <div style={{ backgroundColor: "rgba(100,100,100,0.7)" }} className='cell-content'></div>
            ) 
            :
            (
              <>
                {(nikkeiArrayManual.some(item => item.date === date)) ?
                  (
                    <div className='cell-content'><div>入力済み</div></div>
                  )
                  :
                  (
                    <div style={{ backgroundColor: "rgba(255,0,0,0.7)" }} className='cell-content'><div>未入力</div></div>
                  )
                }
              </>

            )
          }
        </>
      ),
    });
  }



  const getManual = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/nikkeiStock/get/manual', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            setNikkeiArrayManual(data.nikkeiArray);
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  };

  const modalText =
  <>
    <div>本ページは日経平均株価を確認できるページです。</div>
    <div>毎日(土日・祝日・年末年始を除く)午前9時30分にその日の株価の情報が追加され、随時更新されます。</div>
    <div>また約1年半前までの過去の日経平均株価を確認することができます。</div>
  </>

  const printColumns = [
    { title: '日付', dataIndex: 'date', key: 'date' },
    { title: '始値', dataIndex: 'openPrice', key: 'openPrice' },
    { title: '高値', dataIndex: 'highPrice', key: 'highPrice' },
    { title: '安値', dataIndex: 'lowPrice', key: 'lowPrice' },
    { title: '終値', dataIndex: 'closePrice', key: 'closePrice' },
    { title: '前日比', dataIndex: 'beforeDayDiff', key: 'beforeDayDiff'},
    { title: '前日比%', dataIndex: 'beforeDayRatio', key: 'beforeDayRatio'},
  ];

  const printArray = [{
    date: '日付',
    openPrice: '始値',
    highPrice: '高値',
    lowPrice: '安値',
    closePrice: '終値',
    beforeDayDiff: '前日比',
    beforeDayRatio: '前日比%',
  }];


  const tableScroll = printMode ? { x:false, y: false} : { x: true, y: adjustedHeight };
  const pagenation = printMode ? false: { position: ['topRight','bottomRight']};
  const PrintHeader = () => (
    <>
      <div>
        <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false }} pagination={false} />
      </div>
    </>
  );

  useEffect(()=> {
    if(!printMode){
      setTableDataArray(nikkeiArray)
    }
  },[printMode])

  return (
    <>
      <PrintModal setPrintMode={setPrintMode} setTableDataArray={setTableDataArray} nikkeiArray={nikkeiArray} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
      <Loading loading={isLoad}/>
      <ExplanationModal title="日経平均株価ページ" columnName="isExNikkeiStock" text={modalText} isExplanation={isExplanation}/>
      {printMode &&
        <PrintHeader />
      }
      <Table dataSource={tableDataArray} columns={columns} bordered scroll={tableScroll} pagination={pagenation} />
    </>
   
  );
};

export default NikkeiStockTable;
