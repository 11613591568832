// src/DataTable.js
import './components.css';
import React, { useState, useEffect ,useLayoutEffect} from "react";
import { Table,DatePicker, Button } from 'antd';
import Loading from "./Loading";
import { SearchOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { useNavigate } from 'react-router-dom';
import ExplanationModal from './ExplanationModal';
import { getUserInfo,aveArray} from '../commonFunc';
import PrintModal from './PrintModal';

dayjs.locale('ja');

const { RangePicker } = DatePicker;

const TradeSignalWTable = (data) => {
  const [adjustedHeight, setAdjustedHeight] = useState(0);
  const { getTodayNikkei,printMode ,setPrintMode,isPrintModal,setIsPrintModal} = data;

  const [nikkeiArray,setNikkeiArray] = useState([]);
  const [nikkeiColumns,setNikkeColumns] = useState([]);
  const [isLoad,setIsLoad] = useState(false);

  const [tableDataArray,setTableDataArray] = useState(null);
  const [searchDateRange, setSearchDateRange] = useState([]);

  const [isExplanation,setIsExplanation] = useState(false);
  const navigate = useNavigate();

  const handleDateSearch = (dates) => {
    setSearchDateRange(dates);
  };

  const filterDataSourceByDate = () => {
    if (!searchDateRange || searchDateRange.length < 1) {
      setTableDataArray(nikkeiArray);
      return;
    }

    const [start, end] = searchDateRange;
    setTableDataArray( nikkeiArray.filter(item => {
      const itemDate = new Date(item.date);
      (start.$d).setHours(0,0,0,0,0);
      (end.$d).setHours(0,0,0,0,0);
      return itemDate.getTime() >= (start.$d).getTime() && itemDate.getTime() <= (end.$d).getTime();
    }));
  };

  const resetSearchDate = () => {
    setTableDataArray(nikkeiArray);
    setSearchDateRange([]);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
          setIsLoad(true);
          const response = await fetch(process.env.REACT_APP_API_DIR+'/tradeSignal/w/get', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              setNikkeiArray(data.tradeSignalArray);
              getTodayNikkei(data.tradeSignalArray[0]);
            }
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
      setIsLoad(false);
    };
    fetchData();
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        setIsExplanation(data.user.isExTradeSignalW);
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
  },[]);

  
  useEffect(() => {
    createColumns();
    resetSearchDate();
  },[nikkeiArray]);

  useEffect(() => {
    createColumns();
  },[searchDateRange]);

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const createColumns = () => {
    const columns = [
      { 
        title: '日付',
        dataIndex: 'date',
        key: 'date',
        fixed: printMode ? '' : 'left',
        filterDropdown: ({ confirm }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
            picker="date" 
              value={searchDateRange}
              onChange={dates => {
                  handleDateSearch(dates);
              }}
              locale={locale}
            />
            <div style={{ marginTop: 8 }}>
              <Button type="primary" htmlType="submit" onClick={() => {
                filterDataSourceByDate();
                confirm();
              }}>
                検索
              </Button>
              <Button type="default"  className="prev-button" onClick={() => {
                resetSearchDate();
                confirm();
              }}>
                リセット
              </Button>
            </div>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color:"#444"}} className='filter-icon'/>,
        render: date => (
          <>
            <span className='date-pc'>
              {(new Date(date)).getFullYear() + "年" + ((new Date(date).getMonth() + 1)) + "月" + (new Date(date).getDate()) + "日"}
            </span>
            <span className='date-smp'>
            {((new Date(date)).getFullYear()).toString().slice(-2) + "/" + ((new Date(date).getMonth() + 1)) + "/" + (new Date(date).getDate())}
            </span>
          </>
        ),
      },
    ];
    const aveTitleArray = [
      { title: '終値', dataIndex: 'ave1', key: 'ave1' },
    ];
    for(const ave of aveArray){
      const aveKey = `ave${ave}`;
      aveTitleArray.push({ title: `${ave}日平均`, dataIndex: aveKey, key: aveKey});
    }

    for (let i = 0; i < aveTitleArray.length; i++) {
      const average = aveTitleArray[i];
      columns.push({
        ...average,
        render: (ave) => (
          <div style={{ backgroundColor: ave.status == 1  ? "rgba(0,255,0,0.7)" : ave.status == -1 ? "rgba(255,0,0,0.7)" : "" }} className='cell-content'>
            <div>{ave.value}</div>
          </div>
        )
      });
    }
    setNikkeColumns(columns);
  };

  const modalText =
  <>
    <div>本ページは日経平均株価の売買のタイミングを知ることができるページです。</div>
    <div>本日の終値と150日間までの終値の平均値をそれぞれ表示しています。</div>
    <div>画面上で赤色の部分が多くなれば下降傾向、緑色の部分が多くなれば上昇傾向になります。また一列すべてが赤色になれば買い、緑色になれば売りのタイミングです。</div>
    <div>※本ページは上級者向けのページのため、信号が出たときに売買するか、いくら売買するかはご自身の判断で行ってください。</div>
  </>

  const printColumns = [
    { title: '日付', dataIndex: 'date', key: 'date' },
    { title: '終値', dataIndex: 'ave1', key: 'ave1' },
  ];

  const printArray = [{
    date: '日付',
    ave1: '終値',
  }];

  aveArray.forEach(ave => {
    if(ave <=150){
      printColumns.push({ title: `${ave}日平均`, dataIndex: `ave${ave}`, key: `ave${ave}` });
      printArray[0][`ave${ave}`] = `${ave}日平均`;
    }
  });

  const tableScroll = printMode ? { x:false, y: false} : { x: true, y: adjustedHeight };
  const pagenation = printMode ? false: { position: ['topRight','bottomRight']};

  useEffect(()=> {
    if(!printMode){
      setTableDataArray(nikkeiArray)
    }
  },[printMode])

  return (
    <>
    <Loading loading={isLoad}/>
    <PrintModal setPrintMode={setPrintMode} setTableDataArray={setTableDataArray} nikkeiArray={nikkeiArray} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
    <ExplanationModal title="150日間前日対比信号ページ" columnName="isExTradeSignalW" text={modalText} isExplanation={isExplanation}/>
    {printMode &&
      <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
    }
    <Table dataSource={tableDataArray} columns={nikkeiColumns} bordered scroll={tableScroll} pagination={pagenation} />
    </>
  );
};

export default TradeSignalWTable;
