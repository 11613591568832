
const loginCheck = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/login/check', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        return data.res;
    } catch (error) {
        throw error;
    }
}

export {loginCheck};