// src/DataTable.js
import './components.css';
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { StyledMenu } from './Navigation.styled';

const aveArray = [20,30,60,75,90,120,150];
const indexArray =['(20日間)','(30日間)','(60日間)','(75日間)','(90日間)','(120日間)','(150日間)'];

const SideNavigation = ({ open, setOpen }) => {
  const navigate = useNavigate();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1024) {
        setOpen(false);
      }
    }
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function movePage(url) {
    navigate(url);
    setOpen(false);
  }

  const tradeSignalLinks = aveArray.map((ave, index) => {
    const activeClass = (window.location.pathname === "/TradeSignal" && (new URLSearchParams(window.location.search)).get('ave') == ave) ? "active menu-item" : "menu-item";
  
    return (
      <div key={index} className={activeClass} onClick={() => movePage(`/TradeSignal?ave=${ave}`)}>
        売買信号{indexArray[index]}
      </div>
    );
  });


  return (
    <StyledMenu open={open}>
    <div className='side-navigation'>
      <div className="menu-group">
      <div className={window.location.pathname === "/NikkeiStock" ? "active menu-item" : "menu-item"} onClick={() => movePage("/NikkeiStock")}>
          日経平均株価
        </div>
        {tradeSignalLinks}
        <div className={window.location.pathname === "/NikkeiStockFeature" ? "active menu-item" : "menu-item"} onClick={() => movePage("/NikkeiStockFeature")}>
          日経平均予測値
        </div>
        <div className={window.location.pathname === "/TradeSignalW" ? "active menu-item" : "menu-item"} onClick={() => movePage("/TradeSignalW")}>
          150日間前日対比信号
        </div>
        <div className={window.location.pathname === "/TradeSignalWRank" ? "active menu-item" : "menu-item"} onClick={() => movePage("/TradeSignalWRank")}>
          移動平均線順位信号
        </div>
        <div className={window.location.pathname === "/TradeSignalWAveDiff" ? "active menu-item" : "menu-item"} onClick={() => movePage("/TradeSignalWAveDiff")}>
          終値対平均値差信号
        </div>
        <div className={window.location.pathname === "/TwelvePsychology" ? "active menu-item" : "menu-item"} onClick={() => movePage("/TwelvePsychology")}>
          12日間投資家心理
        </div>
        <div className={window.location.pathname === "/upDownRatio" ? "active menu-item" : "menu-item"} onClick={() => movePage("/upDownRatio")}>
          25日騰落レシオ
        </div>
      </div>
    </div>
    </StyledMenu>
  );
};

export default SideNavigation;
