// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useLayoutEffect} from "react";
import { Table, Button, Modal } from 'antd';
import Loading from "./Loading";
import { useNavigate,useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');


const MyHoldingsTable = ({setTotalProfitLoss}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [adjustedHeight, setAdjustedHeight] = useState(0);

  const [isLoad,setIsLoad] = useState(false);
  const [holdingsArray,setHoldingsArray] = useState([]);
  const [isModal,setIsModal] = useState(false);
  const [selectIndex,setSelectIndex] = useState(null);
  const navigate = useNavigate();


  const handleEdit = (index) => {
    const holdingId = holdingsArray[index].holdingId;
    navigate(`/IndividualStock/InsertMyHoldings?stock=${queryParams.get('stock')}&holdingId=${holdingId}`);
  }

  const handleDelete = (index) => {
    setIsModal(true);
    setSelectIndex(index);
  }

  const handleCancel = () => {
    setIsModal(false);
  }

  const deleteUser = async() => {
    setIsModal(false);
    const holdingId = holdingsArray[selectIndex].holdingId;
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/myHoldings/delete', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({holdingId})
        });
        const data = await response.json();
        if(data.isError){
          alert(data.errorText);
        }
        else{
          alert(data.errorText);
          window.location.reload();
        }
    } catch (error) {
        console.log(error);
        alert("ネットワークエラーです");
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoad(true);
          const response = await fetch(process.env.REACT_APP_API_DIR+'/myHoldings/get', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              const holdings = data.myHoldings.map(item => ({
                ...item,
                profitLoss: getProfitLoss(item.confirmPrice,item.isBuy,item.latestClosePrice,item.tradePrice),
                profitLossAll: getProfitLoss(item.confirmPrice,item.isBuy,item.latestClosePrice,item.tradePrice)*item.stockNum,
              }));
              let total = 0;
              for(const val of holdings){
                total+=val.profitLossAll
              }
              setTotalProfitLoss(total);
              setHoldingsArray(holdings);
            }
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
      setIsLoad(false);
    };
    fetchData();

  },[]);

  // useEffect(() => {
  //   resetSearchDate();
  // },[holdingsArray]);

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const columns = [
    { title: '銘柄コード', dataIndex: 'stockCode', key: 'stockCode'},
    { title: '銘柄名', dataIndex: 'stockName', key: 'stockName'},
    { title: '買いか売りか', dataIndex: 'isBuy', key: 'isBuy',
      render: (data)=> {
        return (
          <span>
            {data ? "買い" : "売り"}
          </span>
        );
      }      
    },
    { title: '売買日', dataIndex: 'tradeDate', key: 'tradeDate',
      render: (data)=> {
        return (
          <span>
            {(new Date(data)).getFullYear()}/{(new Date(data)).getMonth()+1}/{(new Date(data)).getDate()}
          </span>
        );
      }      
    },
    { title: '売買時の株価(円)', dataIndex: 'tradePrice', key: 'tradeStock'},
    { title: '売買株数(枚)', dataIndex: 'stockNum', key: 'stockNum'},
    { title: '現在の株価(円)', dataIndex: 'latestClosePrice', key: 'latestClosePrice'},
    { title: '利確時の株価(円)', dataIndex: 'confirmPrice', key: 'confirmStock'},
    { title: '利確日', dataIndex: 'confirmDate', key: 'confirmDate',
      render: (data)=> {
        return (
          <span>
            {data &&
            <>
              {(new Date(data)).getFullYear()}/{(new Date(data)).getMonth()+1}/{(new Date(data)).getDate()}
            </>
            }
          </span>
        );
      }      
    },
    { title: '一株当たりの損益(円)', dataIndex: 'profitLoss', key: 'profitLoss',
      render: (data)=> {
        return (
          <>
            {data > 0 ? (<span style={{color:"green"}} className='right-align'>+{data.toLocaleString()}</span>) : (<span style={{color:"red"}} className='right-align'>{data.toLocaleString()}</span>)}
          </>
        );
      }      
    },
    { title: '損益(円)', dataIndex: 'profitLossAll', key: 'profitLossAll',
      render: (data)=> {
        return (
          <>
            {data > 0 ? (<span style={{color:"green"}} className='right-align'>+{data.toLocaleString()}</span>) : (<span style={{color:"red"}} className='right-align'>{data.toLocaleString()}</span>)}
          </>
        );
      }      
    },
    { title: '編集', dataIndex: 'edit', key: 'edit',
      render: (data, record, index)=> {
        return (
          <Button type="primary" onClick={() => handleEdit(index)}>
            編集
          </Button>
        );
      }      
    },
    { title: '削除', dataIndex: 'delete', key: 'delete',
      render: (data, record, index)=> {
        return (
          <Button type="default" onClick={() => handleDelete(index)}>
            削除
          </Button>
        );
      }      
    },

  ];

  const getProfitLoss = (confirmPrice,isBuy,closePrice,tradePrice) =>{
    if(confirmPrice === null){
     return  isBuy ? closePrice - tradePrice : tradePrice - closePrice
    }else{
      return  isBuy ? confirmPrice - tradePrice : tradePrice - confirmPrice
    }
  }

  return (
    <>
        <Modal
          title="本当に削除しますか？"
          open={isModal}
          onCancel={() => handleCancel()}
          footer={[
            <Button key="cancel" onClick={() => handleCancel()}>
              いいえ
            </Button>,
            <Button key="submit" type="primary" onClick={() => deleteUser()}>
              はい
            </Button>,
          ]}
        >
        </Modal>
      <Loading loading={isLoad}/>
      <Table dataSource={holdingsArray} columns={columns} bordered scroll={{ x: true, y:adjustedHeight }} pagination={{ position: ['topRight','bottomRight']}} />
    </>
   
  );
};

export default MyHoldingsTable;
