import './App.css';
import React from "react";
import { UsersListTable,HeaderGroup } from './components';

const UsersList = () => {

  return (

    <div className='back-content'>
        <HeaderGroup/>
      <div className='body-content'>
        <UsersListTable/>
      </div>
    </div>

  );
};

export default UsersList;
