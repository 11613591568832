import './App.css';
import React, { useState, useEffect } from "react";
import { Button } from 'antd';
import { TradeSignalTable,HeaderGroup } from './components';
import { useLocation,useNavigate } from 'react-router-dom';
import { getUserInfo } from './commonFunc';
import PullToRefresh from 'react-simple-pull-to-refresh';

const TradeSignal = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const aveKey = queryParams.get('ave');
  const [tradeTextArray,setTradeTextArray] = useState([]);
  const [isBuy, setIsBuy] = useState(true);
  const [isTrade,setIsTrade] = useState(false);
  const [titleText,setTitleText] = useState("");
  const [printMode, setPrintMode] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const navigate = useNavigate();

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleChangeBtn = () => {
    setIsBuy(!isBuy);
  }

  const getTodayNikkei = (data) => {
    const ranks = Object.values(data).map((item) => item.rank);
    for (let i = 1; i < ranks.length; i++) {
      if(ranks[i - 1] !== undefined){
        if (ranks[i] !== ranks[i - 1] + 1) {
          setIsTrade(false);
          return;
        }
      }
    }
    setIsTrade(true);
    return;
  };

  useEffect(() => {
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        const investmentPerTwenty = (Number(Math.floor(data.user.investment*0.2))).toLocaleString();
        setTradeTextArray([
          {aveKey:20 , buyText:"株価が下がり始めました",sailText:"株価が上がり始めました"},
          {aveKey:30 , buyText:`1回目まず${investmentPerTwenty}円買ってみましょう`,sailText:`1回目まず${investmentPerTwenty}円売ってみましょう`},
          {aveKey:60 , buyText:`2回目下落中さらに${investmentPerTwenty}円買おう`,sailText:`2回目上昇中さらに${investmentPerTwenty}円売ろう`},
          {aveKey:75 , buyText:`3回目下落中さらに${investmentPerTwenty}円買おう`,sailText:`3回目上昇中さらに${investmentPerTwenty}円売ろう`},
          {aveKey:90 , buyText:`4回目下落中さらに${investmentPerTwenty}円買おう`,sailText:`4回目上昇中さらに${investmentPerTwenty}円売ろう`},
          {aveKey:120 , buyText:`5回目下落中さらに${investmentPerTwenty}円買おう`,sailText:`5回目上昇中さらに${investmentPerTwenty}円売ろう`},
          {aveKey:150 , buyText:`${"【株式バーゲンセール中】最低"+investmentPerTwenty+"円は買おう"}`,sailText:`【高値圏に入りました】最低${investmentPerTwenty}円は売ろう`},
        ]);
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
  },[]);

  useEffect(()=>{
    switch(aveKey){
      case "20":
        setTitleText("売買信号(20日平均)");
        break;
      case "30":
        setTitleText("売買信号(30日平均)");
        break;
      case "60":
        setTitleText("売買信号(60日平均)");
        break; 
      case "75":
        setTitleText("売買信号(75日平均)");
        break;    
      case "90":
        setTitleText("売買信号(90日平均)");
        break;
      case "120":
        setTitleText("売買信号(120日平均)");
        break;
      case "150":
        setTitleText("売買信号(150日平均)");
        break;    
    }
  },[aveKey]);

  const handlePrint = () =>{
    setIsPrintModal(true);
  }

  useEffect(() => {
    if(printMode){
        window.print();
    }
  }, [printMode]);
  
  
  useEffect(() => {
    const afterPrint = () => setPrintMode(false);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  return (

    <div className='back-content'>
      <HeaderGroup handlePrint={handlePrint}/>
      <PullToRefresh onRefresh={handleRefresh}>
        <div className='body-content'>
        <div class="signal-label" style={{backgroundColor:"#888",color:"#fff"}}>{titleText}</div>
          <div className='body-header'>
            {tradeTextArray && tradeTextArray.length > 0 &&
              <div className='signal-text'>{isTrade ? isBuy ? <span style={{color:"red"}}>{(tradeTextArray.find(item => item.aveKey == aveKey)).buyText}</span> : <span style={{color:"green"}}>{(tradeTextArray.find(item => item.aveKey == aveKey)).sailText}</span> : ""}</div>
            }
            <Button  className="change-button" onClick={handleChangeBtn} style={{ backgroundColor: isBuy ? "rgba(0,255,0,0.5)" : "rgba(255,0,0,0.5)" }}>
              {isBuy ? <>売信号に切替</> : <>買信号に切替</>}
            </Button>
          </div>
          {isBuy ? <div class="signal-label" style={{backgroundColor:"rgba(255,0,0,0.5)"}}>買信号を表示中</div> : <div class="signal-label" style={{backgroundColor:"rgba(0,255,0,0.5)"}}>売信号を表示中</div>}
          <TradeSignalTable aveKey={aveKey} isBuy={isBuy} getTodayNikkei={getTodayNikkei} printMode={printMode} setPrintMode={setPrintMode} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
        </div>
      </PullToRefresh>
    </div>

  );
};

export default TradeSignal;
