import './App.css';
import React, { useState, useEffect } from "react";
import { Form, Input, Button} from 'antd';
import { LockOutlined,LockFilled,EyeInvisibleOutlined,EyeTwoTone } from '@ant-design/icons';
import  {HeaderGroup } from './components';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
  const [error, setError] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const onSubmit = (values) => {
    const fetchData = async () => {
      try {
          const response = await fetch(process.env.REACT_APP_API_DIR+'/password/change', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({currentPassword:values.currentPassword,newPassword:values.newPassword,confirmPassword:values.confirmPassword}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              setError(data.errorText);
            }
            else{
              console.log(data.errorText);
              alert(data.errorText);
              navigate("/MyPage");
            }
          }
      } catch (error) {
          setError("ネットワークエラーです");
          console.error('Fetch error:', error);
      }
    };
    fetchData();
  };

  useEffect(() => {

  },[]);

  const tapPrev = () => {
    navigate("/MyPage");
  };

  return (
    <div className='back-content'>
      <div className='main-content'>
        <HeaderGroup/>
        <div className='body-content'>
        <h2 className='page-title'>パスワード変更</h2>
        <div className='page-content'>
        <Form
          name="password_change"
          className="page-form"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
        >
          {error && <p style={{ color: 'red', fontSize: '80%' }}>{error}</p>}
          <Form.Item
            name="currentPassword"
            rules={[{ required: true, message: '現在のパスワードを入力してください' }]}
            label={<span style={{fontSize:"120%"}}>現在のパスワード</span>}
            labelCol={{span:24}}
          >
            <Input.Password
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              prefix={<LockOutlined />}
              style={{fontSize:"120%"}}
              type="password"
              visibilityToggle={{ visible: showCurrentPassword, onVisibleChange: setShowCurrentPassword }}
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: '新しいパスワードを入力してください' }]}
            label={<span style={{fontSize:"120%"}}>新しいパスワード</span>}
            labelCol={{span:24}}
          >
            <Input.Password
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              prefix={<LockFilled />}
              style={{fontSize:"120%"}}
              type="password"
              visibilityToggle={{ visible: showNewPassword, onVisibleChange: setShowNewPassword }}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[{ required: true, message: '新しいパスワード（再入力）を入力してください' }]}
            label={<span style={{fontSize:"120%"}}>新しいパスワード（再入力）</span>}
            labelCol={{span:24}}
          >
            <Input.Password
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              prefix={<LockFilled />}
              style={{fontSize:"120%"}}
              type="password"
              visibilityToggle={{ visible: showConfirmPassword, onVisibleChange: setShowConfirmPassword }}
            />
          </Form.Item>

          <Form.Item>
            <Button type="default"  className="prev-button" onClick={tapPrev}>
              戻る
            </Button>
            <Button type="primary" htmlType="submit" className="login-form-button">
              更新
            </Button>
          </Form.Item>
        </Form>
        </div>
        </div>
      </div>
    </div>

  );
};

export default ChangePassword;
