// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useLayoutEffect} from "react";
import { Table,DatePicker, Button, Modal } from 'antd';
import Loading from "./Loading";
import { useNavigate } from 'react-router-dom';
// import { SearchOutlined } from '@ant-design/icons';
// import locale from 'antd/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

// const { RangePicker } = DatePicker;

const UsersListTable = () => {
  const [adjustedHeight, setAdjustedHeight] = useState(0);

  const [isLoad,setIsLoad] = useState(false);
  const [usersArray,setUsersArray] = useState([]);
  const [isModal,setIsModal] = useState(false);
  const [selectIndex,setSelectIndex] = useState(null);
  const navigate = useNavigate();
  // const [searchDateRange, setSearchDateRange] = useState([]);

  // const handleDateSearch = (dates) => {
  //   setSearchDateRange(dates);
  // };

  // const filterDataSourceByDate = (data) => {
  //   if (!searchDateRange || searchDateRange.length < 1) {
  //     setTableDataArray(data);
  //     return;
  //   }

  //   const [start, end] = searchDateRange;
  //   setTableDataArray( data.filter(item => {
  //     const itemDate = new Date(item.date);
  //     (start.$d).setHours(0,0,0,0,0);
  //     (end.$d).setHours(0,0,0,0,0);
  //     return itemDate.getTime() >= (start.$d).getTime() && itemDate.getTime() <= (end.$d).getTime();
  //   }));
  // };

  // const resetSearchDate = () => {
  //   setSearchDateRange([]);
  // }

  const handleEdit = (index) => {
    const userId = usersArray[index].userId;
    navigate("/EditUserInfo?id="+userId);
  }

  const handleDelete = (index) => {
    setIsModal(true);
    setSelectIndex(index);
  }

  const handleCancel = () => {
    setIsModal(false);
  }

  const deleteUser = async() => {
    setIsModal(false);
    const userId = usersArray[selectIndex].userId;
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/userInfo/delete', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({userId})
        });
        const data = await response.json();
        if(data.isError){
          alert(data.errorText);
        }
        else{
          alert(data.errorText);
          window.location.reload();
        }
    } catch (error) {
        console.log(error);
        alert("ネットワークエラーです");
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoad(true);
          const response = await fetch(process.env.REACT_APP_API_DIR+'/userInfo/get/list', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              setUsersArray(data.userList);
            }
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
      setIsLoad(false);
    };
    fetchData();

  },[]);

  // useEffect(() => {
  //   resetSearchDate();
  // },[usersArray]);

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const columns = [
    { title: '名前', dataIndex: 'name', key: 'name'},
    { title: 'メールアドレス', dataIndex: 'mail', key: 'mail'},
    { title: '日経平均株価入力', dataIndex: 'isAuto', key: 'isAuto',
      render: (data)=> {
        return (
          <span>
            {data ? "自動" : "手動"}
          </span>
        );
      }      
    },
    { title: '投資金額', dataIndex: 'investment', key: 'investment'},
    { title: '権限', dataIndex: 'isAdmin', key: 'isAdmin',
      render: (data)=> {
        return (
          <span>
            {data ? "管理者" : "一般ユーザー"}
          </span>
        );
      }      
    },
    { title: '登録日', dataIndex: 'createDate', key: 'createDate',
      render: (data)=> {
        return (
          <span>
            {(new Date(data)).getFullYear()}/{(new Date(data)).getMonth()+1}/{(new Date(data)).getDate()}
          </span>
        );
      }      
    },
    { title: '個別株の閲覧権限', dataIndex: 'isIndividualStock', key: 'cisIndividualStock',
      render: (data)=> {
        return (
          <span>
            {data ? "あり" : "なし"}
          </span>
        );
      }      
    },
    { title: 'メールマガジン', dataIndex: 'isMailRev', key: 'isMailRev',
      render: (data)=> {
        return (
          <span>
            {data ? "受け取る" : "受け取らない"}
          </span>
        );
      }      
    },
    { title: '備考', dataIndex: 'remarks', key: 'remarks',
      render: (data)=> {
        return (
          <div style={{minWidth:"200px"}}>
            <p>{data}</p>
          </div>
        );
      }    
    },
    { title: '編集', dataIndex: 'edit', key: 'edit',
      render: (data, record, index)=> {
        return (
          <Button type="primary" onClick={() => handleEdit(index)}>
            編集
          </Button>
        );
      }      
    },
    { title: '削除', dataIndex: 'delete', key: 'delete',
      render: (data, record, index)=> {
        return (
          <Button type="default" onClick={() => handleDelete(index)}>
            削除
          </Button>
        );
      }      
    },

  ];

  return (
    <>
        <Modal
          title="本当に削除しますか？"
          open={isModal}
          onCancel={() => handleCancel()}
          footer={[
            <Button key="cancel" onClick={() => handleCancel()}>
              いいえ
            </Button>,
            <Button key="submit" type="primary" onClick={() => deleteUser()}>
              はい
            </Button>,
          ]}
        >
        </Modal>
      <Loading loading={isLoad}/>
      <Table dataSource={usersArray} columns={columns} bordered scroll={{ x: true, y:adjustedHeight }} pagination={{ position: ['topRight','bottomRight']}} />
    </>
   
  );
};

export default UsersListTable;
