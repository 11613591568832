// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useLayoutEffect} from "react";
// import { getUserInfo} from '../../commonFunc';
import { Table, Modal, Button} from 'antd';
import { useNavigate,useLocation } from 'react-router-dom';
import Loading from "./Loading";
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
// import ExplanationModal from './ExplanationModal';
dayjs.locale('ja');


const IndustryStockTable = (data) => {
  const { printMode } = data;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [adjustedHeight, setAdjustedHeight] = useState(0);

  const [nikkeiArray,setNikkeiArray] = useState([]);
  const [isLoad,setIsLoad] = useState(false);
  const [isModal,setIsModal] = useState(false);
  const [title,setTitle] = useState(null);
  const [individualArray,setIndividualArray] = useState([]);
  const navigate = useNavigate();
  
  const [tableDataArray,setTableDataArray] = useState(null);

  // const [isExplanation,setIsExplanation] = useState(false);
  const handleClose = () =>{
    setIsModal(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoad(true);
          const response = await fetch(process.env.REACT_APP_API_DIR+'/industryStock/get', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              setNikkeiArray(data.nikkeiArray);
            }
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
      setIsLoad(false);
    };
    fetchData();
    // getUserInfo().then(data =>{
    //   if(data.isError){
    //     navigate("/");
    //   }
    //   else{
    //     setIsExplanation(data.user.isExNikkeiStock);
    //   }
    // }).catch(err=>{
    //   console.log(err);
    //   navigate("/");
    // });

  },[]);

  useEffect(() => {
    setTableDataArray(nikkeiArray.map((item, index) => ({ ...item, index: (33 - index) })));
  },[nikkeiArray]);

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleIndustryStock = (industryName) =>{
    const fetchData = async (industryName) => {
      try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/individualStock/get/industryName', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({industryName:industryName.trim()}),
        });
          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              setIndividualArray(data.nikkeiArray);
            }
            setTitle(`${industryName}銘柄`);
            setIsModal(true);
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
    }
    fetchData(industryName);
  }

  const handleIndividualStock = (stockCode) =>{
    navigate(`/IndividualStock/TradeSignal?ave=20&stock=${stockCode}`);
  }

  const columns = [
    { title: '順位', dataIndex: 'index', key: 'index'},
    { title: '業種名', dataIndex: 'industryName', key: 'industryName',
      render: industryName => (
        <>
          <Button  onClick={() => handleIndustryStock(industryName)}>{industryName}</Button>
        </>
      ),
    },
    { title: '終値', dataIndex: 'closePrice', key: 'closePrice' },
    { title: '前日比', dataIndex: 'beforeDayDiff', key: 'beforeDayDiff',
        render: beforeDayDiff => (
            <>
            {beforeDayDiff > 0 ? (
                <span style={{color:"green"}}>
                +{beforeDayDiff}
                </span>
            ) : (
              <>
                {beforeDayDiff === 0 ? (
                  <span style={{color:"black"}}>
                  {beforeDayDiff}
                  </span>
                ) : (
                  <span style={{color:"red"}}>
                  {beforeDayDiff}
                  </span>
                )}
              </>
            )}
            </>
        ),
    },
    { title: '前日比%', dataIndex: 'beforeDayRatio', key: 'beforeDayRatio',
        render: (beforeDayRatio)=> {
          if(beforeDayRatio > 0) {
            return (
              <span style={{color:"green"}}>
                +{beforeDayRatio}
              </span>
            );
          }
          else if(beforeDayRatio === 0){
            return (
              <span style={{color:"black"}}>
                {beforeDayRatio}
              </span>
            );

          }   
          else{
            return (
              <span style={{color:"red"}}>
                {beforeDayRatio}
              </span>
            );
  
          }
      }         
    },
  ];

  const modalText =
  <>
    <Table dataSource={individualArray} 
      columns={[
        { title: '銘柄コード', dataIndex: 'stockCode', key: 'stockCode',
          render: stockCode => (
            <>
              <Button  onClick={() => handleIndividualStock(stockCode)}>{stockCode}</Button>
            </>
          ),
        },
        { title: '銘柄名', dataIndex: 'stockName', key: 'stockName'}]}
      bordered
      pagination={{ position: ['topRight','bottomRight']}} 
    />
  </>

const printColumns = [
  { title: '順位', dataIndex: 'index', key: 'index' },
  { title: '業種名', dataIndex: 'industryName', key: 'industryName' },
  { title: '終値', dataIndex: 'closePrice', key: 'closePrice' },
  { title: '前日比', dataIndex: 'beforeDayDiff', key: 'beforeDayDiff'},
  { title: '前日比%', dataIndex: 'beforeDayRatio', key: 'beforeDayRatio'},
];

const printArray = [{
  index: '順位',
  industryName:'業種名',
  closePrice: '終値',
  beforeDayDiff: '前日比',
  beforeDayRatio: '前日比%',
}];


const tableScroll = printMode ? { x:false, y: false} : { x: true, y: adjustedHeight };
const pagenation = printMode ? false: { position: ['topRight','bottomRight']};

  return (
    <>
      <Loading loading={isLoad}/>
      {/* <ExplanationModal title="業種別銘柄" columnName="isExNikkeiStock" text={modalText} isExplanation={isExplanation}/> */}
      {printMode &&
        <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
      }
      <Table dataSource={tableDataArray} columns={columns} bordered scroll={tableScroll} pagination={pagenation} />

      <Modal
        title={title}
        open={isModal}
        onCancel={() => handleClose()}
        footer={[
          <span style={{textAlign:"start"}}>{modalText}</span>,
          <>
            <div style={{margin:"20px 0"}}>
              <Button type="primary"  onClick={() => handleClose()}>
                  閉じる
              </Button>
            </div>
          </>
        ]}
      >
      </Modal>
    </>
   
  );
};

export default IndustryStockTable;
