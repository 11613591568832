// src/DataTable.js
import './components.css';
import {Modal,Button,DatePicker} from 'antd';
import React, { useState, useEffect ,useRef} from "react";
import locale from 'antd/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import moment from 'moment';
const { RangePicker } = DatePicker;

dayjs.locale('ja');

const PrintModal = ({setPrintMode,setTableDataArray,nikkeiArray,isPrintModal,setIsPrintModal}) => {

  const [searchDateRange, setSearchDateRange] = useState([]);
  const isInitialMount = useRef(true);
  const isCloseMount = useRef(false);

  const handleClose = () =>{
    setIsPrintModal(false)
    isCloseMount.current = true
  }

  const handleDateSearch = (dates) => {
    setSearchDateRange(dates);
  };

  const filterDataSourceByDate = (data) => {
    if (!searchDateRange || searchDateRange.length < 1) {
      // setTableDataArray(data);
    }
    else{
      const [start, end] = searchDateRange;
      setTableDataArray(data.filter(item => {
        const itemDate = new Date(item.date);
        (start.$d).setHours(0,0,0,0,0);
        (end.$d).setHours(0,0,0,0,0);
        return itemDate.getTime() >= (start.$d).getTime() && itemDate.getTime() <= (end.$d).getTime();
      }));
    }
    setIsPrintModal(false)
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false; // 初回ロード時にはフラグをfalseに設定
    } 
    else {
      if (!isPrintModal && !isCloseMount.current) {
        setTimeout(() => {
          setPrintMode(true);
        }, 200);
      }
    }
    if(isCloseMount.current){
      isCloseMount.current = false
    }
  }, [isPrintModal]);

  const disabledDate = (current) => {
    const start = moment(nikkeiArray[0].date);
    const end = moment(nikkeiArray[nikkeiArray.length-1].date);
    return current && (current.isBefore(end, 'day') || current.isAfter(start, 'day'));
  }

  return (
    <Modal
      title="印刷する範囲を選択してください"
      open={isPrintModal}
      onCancel={() => handleClose()}
      footer={[
          <>
            <div style={{ textAlign:"start" }}>
              <div>全範囲を選択する場合は日付の欄を空欄にして「印刷」ボタンを押してください</div>
              <RangePicker
                value={searchDateRange}
                onChange={dates => {
                    handleDateSearch(dates);
                }}
                locale={locale}
                disabledDate={disabledDate}
              />
              <div style={{margin:"20px 0"}}>
                <Button type="primary" htmlType="submit" style={{margin:"0 5px"}} onClick={() => {
                    filterDataSourceByDate(nikkeiArray);
                  }}>
                    印刷
                  </Button>
                <Button style={{margin:"0 5px"}} onClick={handleClose}>
                    閉じる
                </Button>
              </div>
            </div>
          </>
      ]}
    >
    </Modal>
  );
};

export default PrintModal;
