import './App.css';
import { Checkbox, Button } from 'antd';
import React, { useState, useEffect } from "react";
import { getUserInfo, loginCheck} from './commonFunc';
import { useNavigate } from 'react-router-dom';

const Explanation = () => {
    const navigate = useNavigate();
    const [isHidden, setIsHidden] = useState(false);
    
    const handleChange = (e) =>{
        setIsHidden(e.target.checked);
    }
    const handleClose = () =>{
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_DIR+'/userInfo/update/explanation', {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({isHidden}),
                });
      
                const data = await response.json();
                if(data){
                  if(data.isError){
                    alert(data.errorText);
                  }
                }
            } catch (error) {
                console.error('Fetch error:', error);
            } 
          };
          fetchData();
          navigate("/NikkeiStock");
    }

    useEffect(() => {
        loginCheck().then(data =>{
            if(!data){
              navigate("/");
            }
          }).catch(err=>{
            navigate("/");
          });
      },[]);
    return (
        <>
        <div className='explanation-body'>
            <div className='explanation-view'>
                <div>
                    <h2>株式天照ソフト(アマテラス)へようこそ</h2>
                    <div style={{margin:"20px 0" }}>
                        <p>本ソフトはAI人工知能を駆使し、売買タイミングを知らせるソフトです。</p>
                        <p>まずは下記の手順に従い設定を行いましょう。</p>
                        <ul>
                            <li>
                                右上の人型アイコンをタップ→マイページ→ユーザー情報編集画面から投資金額（日経平均株の投資に使える金額）を入力し、更新ボタンを押す。
                            </li>
                            <li>                                
                                続いてパスワード変更ボタンよりパスワード変更画面を開き、現在のパスワード(初期パスワード)と新しいパスワードを2回入力して更新ボタンを押す。
                            </li>
                            <li>
                                これで設定は完了です。画面左のメニュー(スマホの場合は左上の三本線ボタン)より日経平均株価と日経平均の売買タイミングを確認できます。
                            </li>
                        </ul>
                        <p>本ソフトをスマートフォンのホーム画面に表示する方法</p>
                        <ul>
                            <li>
                                LINEから開いている場合は画面右下の︙(縦三点リーダ)をタップし、ブラウザを開くを選択する。(既にブラウザで開いている場合は省略)
                            </li>
                            <li>                                
                                <p>その後ブラウザでの表示に切り替わったら端末ごとで下記設定を行う。</p>
                                <p>【iPhoneの場合】</p>
                                <p>　画面下部の真ん中にある共有ボタン(四角から↑が飛び出した記号)をタップし、表示されたメニューの中の「ホーム画面に追加」を選択する。</p>
                                <p>【Androidの場合】</p>
                                <p>　画面右上の︙(縦三点リーダ)をタップし、表示されたメニューの中の「ホーム画面に追加」を選択する。</p>
                            </li>
                        </ul>
                        <p>本ソフトをPCのデスクトップ上に表示する方法</p>
                        <ul>
                            <li>
                                PCにインストールされているブラウザで「https://amaterasu-stock.com/」にアクセスする
                            </li>
                            <li>                                
                                アクセスしたらURL部分を全選択する
                            </li>
                            <li>                                
                               URLが選択されたら左クリックをしながらデスクトップ画面にカーソルを動かし、左クリックを離す
                            </li>
                        </ul>
                        <div style={{margin:"20px 0"}}>
                            <Checkbox onChange={handleChange}>次回以降のログイン時にこの説明文を表示しない場合はチェックを入れてください</Checkbox>
                        </div>
                        <div style={{margin:"20px 0"}}>
                            <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleClose}>
                                閉じる
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};
export default Explanation;
