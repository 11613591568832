import '../App.css';
import {React ,useEffect,useState} from "react";
import { UpDownRatioIndividualTable,HeaderGroup } from './components';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { useLocation,useNavigate } from 'react-router-dom';
import { Button, AutoComplete } from 'antd';
import { getIndividualStock } from '../commonFunc';
import { SearchOutlined } from '@ant-design/icons';

const UpDownRatioIndividual = () => {
  const [printMode, setPrintMode] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [individualStockArray,setIndividualStockArray] = useState([]);
  const [selectIndividualStock,setSelectIndividualStock] = useState(queryParams.get('stock') === "null" ? "" : queryParams.get('stock'));
  const [selectIndividualStockTmp,setSelectIndividualStockTmp] = useState(queryParams.get('stock') === "null" ? "" : queryParams.get('stock'));
  const [isShow,setIsShow] = useState(false);

  const handleRefresh = () => {
    window.location.reload();
  };
  
  function handleIsModal(isModal){
    setIsShow(isModal)
  }

  function handleInputChange(value){
    setSelectIndividualStockTmp(value);
    if (individualStockArray.some(item => item.stockCode === value)) {
      setSelectIndividualStock(value);
    }
  }

  useEffect(() =>{
    getIndividualStock().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        setIndividualStockArray(data.nikkeiArray);
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
  },[])

  useEffect(() => {
    if(queryParams.get('stock') !== selectIndividualStock){
      window.location.href = `/IndividualStock/UpDownRatioIndividual?stock=${selectIndividualStock}`;
    }
  },[selectIndividualStock]);

  const handlePrint = () =>{
    setIsPrintModal(true);
  }

  useEffect(() => {
    if(printMode){
      window.print();
    }
  }, [printMode]);
  
  
  useEffect(() => {
    const afterPrint = () => setPrintMode(false);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);
  
  return (
    <div className='back-content'>
      <HeaderGroup handlePrint={handlePrint}/>
      <PullToRefresh onRefresh={handleRefresh}>
        <div className='body-content'>
          <div class="signal-label" style={{backgroundColor:"#888",color:"#fff"}}>
            <div>
              <span>25日騰落レシオ　</span>
              {(selectIndividualStock && individualStockArray && individualStockArray.length > 0) &&
                  <span style={{color:"#fff"}}>{(individualStockArray.find(stock => stock.stockCode === selectIndividualStock)).stockName}({selectIndividualStock})</span>
                }
            </div>
            <div className='print-no-display'>
              {/* <Select defaultValue={selectIndividualStock} style={{ minWidth: 160 }} onChange={handleChange}>
                {individualStockArray && individualStockArray.map(data => (
                  <Option value={data.stockCode}>{data.stockName}({data.stockCode})</Option>
                ))}
              </Select> */}

              <AutoComplete
                value={selectIndividualStockTmp}
                style={{ minWidth: 200}}
                options={individualStockArray.map(data => ({
                  value: data.stockCode,
                  label: `${data.stockName}(${data.stockCode})`
                }))}
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={handleInputChange}
              />
              <Button  icon={<SearchOutlined />} onClick={e => handleIsModal(true)}>
              </Button>
            </div>
            <div style={{fontSize:"85%",margin:"0 2px"}} className='print-no-display'>
              <p>銘柄コードを入力または入力欄をリセットして銘柄を選択</p>
              <p>虫眼鏡アイコンをタップで同業種の銘柄を検索できます</p>
            </div>
          </div>
          <UpDownRatioIndividualTable stockCode={selectIndividualStock} printMode={printMode} setPrintMode={setPrintMode} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
        </div> 
      </PullToRefresh>
    </div>

  );
};

export default UpDownRatioIndividual;
