import '../App.css';
import {React, useState,useEffect} from "react";
import { IndustryStockTable,HeaderGroup } from './components';
import PullToRefresh from 'react-simple-pull-to-refresh';

const IndustryStock = () => {
  const [printMode, setPrintMode] = useState(false);
  const handleRefresh = () => {
    window.location.reload();
  };

  const handlePrint = () =>{
    setPrintMode(true);
  }

  useEffect(() => {
    if(printMode){
      setTimeout(() => {
        window.print();
      }, 100);
    }
  }, [printMode]);
  
  
  useEffect(() => {
    const afterPrint = () => setPrintMode(false);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  return (

    <div className='back-content'>
        <HeaderGroup handlePrint={handlePrint}/>
        <PullToRefresh onRefresh={handleRefresh}>
          <div className='body-content'>
            <div class="signal-label" style={{backgroundColor:"#888",color:"#fff"}}>業種別銘柄</div>
            <div className="explain-text" style={{fontWeight:"bold",marginLeft:"10px"}}>業種別銘柄33種を前日比割合が低い順に表示しています</div>
          <IndustryStockTable printMode={printMode} />
          </div>
        </PullToRefresh>
    </div>

  );
};

export default IndustryStock;