// src/DataTable.js
import './components.css';
import React, { useState, useEffect } from "react";
import TopHeader from './TopHeader';
import SideNavigation from './SideNavigation';

const HeaderGroup = ({handlePrint}) => {
  const [open, setOpen] = useState(false);

  const handleIsOpen = (value) =>{
    setOpen(value);
  };

  return (
    <>
        <TopHeader open={open} setOpen={handleIsOpen} handlePrint={handlePrint}/>
        <SideNavigation open={open} setOpen={handleIsOpen}/>
    </>
  );
};

export default HeaderGroup;
