import '../App.css';
import {React, useState,useEffect} from "react";
import { IndustryStockTable,HeaderGroup, FundamentalsTable } from './components';
import PullToRefresh from 'react-simple-pull-to-refresh';

const Fundamentals = () => {
  const [printMode, setPrintMode] = useState(false);
  const handleRefresh = () => {
    window.location.reload();
  };

  const handlePrint = () =>{
    setPrintMode(true);
  }

  useEffect(() => {
    if(printMode){
      setTimeout(() => {
        window.print();
      }, 100);
    }
  }, [printMode]);
  
  
  useEffect(() => {
    const afterPrint = () => setPrintMode(false);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  return (

    <div className='back-content'>
        <HeaderGroup handlePrint={handlePrint}/>
        <PullToRefresh onRefresh={handleRefresh}>
          <div className='body-content'>
            <div class="signal-label" style={{backgroundColor:"#888",color:"#fff"}}>ファンダメンタルズ指標</div>
            <div className="explain-text" style={{marginLeft:"10px"}}><span style={{fontWeight:"bold"}}>PER</span>・・・株価収益率=株価÷1株当たり純利益(EPS)=15倍を基準に割高割安を見る</div>
            <div className="explain-text" style={{marginLeft:"10px"}}><span style={{fontWeight:"bold"}}>PSR</span>・・・株価売上高倍率=株価÷売上高=全企業の中央値は0.7倍、企業の売上の何倍かを見る指標です</div>
            <div className="explain-text" style={{marginLeft:"10px"}}><span style={{fontWeight:"bold"}}>配当利回り</span>・・・1株当たりの年間配当金÷株価=配当の高い企業を選ぶ人も多いです</div>
            <div className="explain-text" style={{marginLeft:"10px"}}><span style={{fontWeight:"bold"}}>貸借倍率</span>・・・制度信用取引における売り残に対しての買い残の倍率のことです</div>
            <div className="explain-text" style={{marginLeft:"10px"}}>売買信号が出たら10年間のチャート、騰落レシオ、サイコロジカル、RSI、MACD、終値と5日・25日・75日の乖離率、会社情報等を確認して自己責任で売買してください</div>
          <FundamentalsTable printMode={printMode} />
          </div>
        </PullToRefresh>
    </div>

  );
};

export default Fundamentals;