// src/DataTable.js
import './components.css';
import React, { useState, useEffect } from "react";
import { Table, Modal, Button} from 'antd';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');


const IndustryStockModal = ({ individualArray, isShow ,handleIsModal} ) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentURL = location.pathname
  const [isModal,setIsModal] = useState(false);
  const [title,setTitle] = useState(null);

  const handleClose = () =>{
    handleIsModal(false);
    setIsModal(false)
  }

  useEffect(() => {
    if(isShow){
      setTitle(`${individualArray[0].industryName}銘柄`);
      setIsModal(true);
    }
  },[isShow]);


  const handleIndividualStock = (stockCode) =>{
    if(currentURL === "/IndividualStock/TradeSignal"){
      window.location.href = `${currentURL}?ave=${queryParams.get("ave")}&stock=${stockCode}`;
    }
    else{
      window.location.href = `${currentURL}?stock=${stockCode}`;
    }
    
  }

  const modalText =
  <>
    <Table dataSource={individualArray} 
      columns={[
        { title: '銘柄コード', dataIndex: 'stockCode', key: 'stockCode',
          render: stockCode => (
            <>
              <Button  onClick={() => handleIndividualStock(stockCode)}>{stockCode}</Button>
            </>
          ),
        },
        { title: '銘柄名', dataIndex: 'stockName', key: 'stockName'}]}
      bordered
      pagination={{ position: ['topRight','bottomRight']}} 
    />
  </>

  return (
    <>
      <Modal
        title={title}
        open={isModal}
        onCancel={() => handleClose()}
        footer={[
          <span style={{textAlign:"start"}}>{modalText}</span>,
          <>
            <div style={{margin:"20px 0"}}>
              <Button type="primary"  onClick={() => handleClose()}>
                  閉じる
              </Button>
            </div>
          </>
        ]}
      >
      </Modal>
    </>
   
  );
};

export default IndustryStockModal;
