import './App.css';
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Radio} from 'antd';
import { UserOutlined,MailOutlined,DatabaseOutlined,LockOutlined,MoneyCollectOutlined } from '@ant-design/icons';
import { HeaderGroup } from './components';
import { useNavigate } from 'react-router-dom';
const { TextArea } = Input; 

const InsertUserInfo = () => {
  const [error, setError] = useState('');
  const [isAuto,setIsAuto] = useState(true);
  const [isAdmin,setIsAdmin] = useState(false);
  const [isIndividualStock,setIsIndividualStock] = useState(false);
  const navigate = useNavigate();
  const onSubmit = (values) => {
    const fetchData = async () => {
      try {
          const response = await fetch(process.env.REACT_APP_API_DIR+'/userInfo/insert', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({name:values.name,mail:values.email,password:values.password,investment:values.investment,isAuto,manualChangeDate:isAuto ? null : values.manualChangeDate,remarks:values.remarks,isAdmin,isIndividualStock}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              setError(data.errorText);
            }
            else{
              console.log(data.errorText);
              alert(data.errorText);
              navigate("/MyPage");
            }
          }
      } catch (error) {
          setError("ネットワークエラーです");
          console.error('Fetch error:', error);
      }
    };
    fetchData();
  };

  function  onChange(value){
    setIsAuto(value);
  }

  useEffect(() => {
  },[]);

  const tapPrev = () => {
    navigate("/MyPage");
  };

  return (
    <div className='back-content'>
      <div className='main-content'>
        <HeaderGroup/>
        <div className='body-content'>
        <h2 className='page-title'>ユーザー新規登録</h2>
          <div className='page-content'>
          <Form
            name="password_change"
            className="page-form"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
          >
            {error && <p style={{ color: 'red', fontSize: '80%' }}>{error}</p>}
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'お名前を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>お名前</span>}
              labelCol={{span:24}}
            >
              <Input
                style={{fontSize:"120%"}}
                prefix={<UserOutlined />}
                type="text"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'メールアドレスを入力してください' }]}
              label={<span style={{fontSize:"120%"}}>メールアドレス</span>}
              labelCol={{span:24}}
            >
              <Input prefix={<MailOutlined />} 
                style={{fontSize:"120%"}}
                type='email'
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'パスワードを入力してください' }]}
              label={<span style={{fontSize:"120%"}}>パスワード</span>}
              labelCol={{span:24}}
            >
              <Input
                style={{fontSize:"120%"}}
                prefix={<LockOutlined />}
                type="password"
              />
            </Form.Item>
            <Form.Item
              name="investment"
              rules={[{ required: true, message: '投資金額を入力してください' }]}
              label={<span style={{fontSize:"120%"}}>投資金額(円)</span>}
              labelCol={{span:24}}
            >
              <Input prefix={<MoneyCollectOutlined />} 
                style={{fontSize:"120%"}}
                type='number'
              />
            </Form.Item>
            <Form.Item
              name="isAuto"
              rules={[{ required: true, message: '日経平均株価の入力方法を選択してください' }]}
              label={<span style={{fontSize:"120%"}}>日経平均株価の入力方法</span>}
              labelCol={{span:24}}
            >
              <Radio.Group onChange={e=>onChange(e.target.value)} value={isAuto} name="isAuto">
                <Radio value={1}><span style={{fontSize:"120%"}}>自動</span></Radio>
                <Radio value={0}><span style={{fontSize:"120%"}}>手動</span></Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="manualChangeDate"
              label={<span style={{fontSize:"120%"}}>日経平均株価の入力方法を手動に切り替えた日付</span>}
              labelCol={{span:24}}
            >
              <Input prefix={<DatabaseOutlined />} 
                type='date'
                style={{fontSize:"120%"}}
                disabled={isAuto}
              />
            </Form.Item>
            <Form.Item
                name="isAdmin"
                rules={[{ required: true, message: '権限を選択してください' }]}
                label={<span style={{fontSize:"120%"}}>権限</span>}
                labelCol={{span:24}}
              >
              <Radio.Group onChange={e=>setIsAdmin(e.target.value)} value={isAdmin} name="isAdmin">
                <Radio value={1}><span style={{fontSize:"120%"}}>管理者</span></Radio>
                <Radio value={0}><span style={{fontSize:"120%"}}>一般ユーザー</span></Radio>
              </Radio.Group>
              </Form.Item>
            <Form.Item>
            <Form.Item
              name="isIndividualStock"
              rules={[{ required: true, message: '個別株の閲覧権限を選択してください' }]}
              label={<span style={{fontSize:"120%"}}>個別株の閲覧権限</span>}
              labelCol={{span:24}}
              initialValue={isIndividualStock}
            >
            <Radio.Group onChange={e=>setIsIndividualStock(e.target.value)} value={isIndividualStock} name="isIndividualStock">
              <Radio value={1}><span style={{fontSize:"120%"}}>あり</span></Radio>
              <Radio value={0}><span style={{fontSize:"120%"}}>なし</span></Radio>
            </Radio.Group>
            </Form.Item>
            <Form.Item
                name="remarks"
                label={<span style={{fontSize:"120%"}}>備考</span>}
                labelCol={{span:24}}
              >
              <TextArea></TextArea>
            </Form.Item>
              <Button type="default"  className="prev-button" onClick={tapPrev}>
                戻る
              </Button>
              <Button type="primary" htmlType="submit" className="login-form-button">
                登録
              </Button>
            </Form.Item>
          </Form>
          </div>
        </div>
      </div>
    </div>

  );
};

export default InsertUserInfo;
