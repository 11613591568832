// src/DataTable.js
import './components.css';
import React, { useState, useEffect ,useLayoutEffect} from "react";
import { Table,DatePicker, Button } from 'antd';
import Loading from "./Loading";
import { SearchOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { useNavigate } from 'react-router-dom';
import ExplanationModal from './ExplanationModal';
import { aveWArray, getUserInfo} from '../commonFunc';
import PrintModal from './PrintModal';

dayjs.locale('ja');

const { RangePicker } = DatePicker;

const TradeSignalWRankTable = (data) => {
  const [adjustedHeight, setAdjustedHeight] = useState(0);
  const { getTodayNikkei ,printMode,setPrintMode,isPrintModal,setIsPrintModal} = data;
  const [nikkeiArray,setNikkeiArray] = useState([]);
  const [nikkeiColumns,setNikkeColumns] = useState([]);
  const [isLoad,setIsLoad] = useState(false);

  const [tableDataArray,setTableDataArray] = useState(null);
  const [searchDateRange, setSearchDateRange] = useState([]);

  const [isExplanation,setIsExplanation] = useState(false);
  const navigate = useNavigate();

  const handleDateSearch = (dates) => {
    setSearchDateRange(dates);
  };

  const filterDataSourceByDate = () => {
    if (!searchDateRange || searchDateRange.length < 1) {
      setTableDataArray(nikkeiArray);
      return;
    }

    const [start, end] = searchDateRange;
    setTableDataArray( nikkeiArray.filter(item => {
      const itemDate = new Date(item.date);
      (start.$d).setHours(0,0,0,0,0);
      (end.$d).setHours(0,0,0,0,0);
      return itemDate.getTime() >= (start.$d).getTime() && itemDate.getTime() <= (end.$d).getTime();
    }));
  };

  const resetSearchDate = () => {
    setTableDataArray(nikkeiArray);
    setSearchDateRange([]);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
          setIsLoad(true);
          const response = await fetch(process.env.REACT_APP_API_DIR+'/tradeSignal/w/get/rank', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              setNikkeiArray(data.tradeSignalArray);
              getTodayNikkei(data.tradeSignalArray[0]);
            }
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
      setIsLoad(false);
    };
    fetchData();
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        setIsExplanation(data.user.isExTradeSignalWRank);
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
  },[]);

  
  useEffect(() => {
    createColumns();
    resetSearchDate();
  },[nikkeiArray]);

  useEffect(() => {
    createColumns();
  },[searchDateRange]);

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const createColumns = () => {
    const columns = [
      { 
        title: '日付',
        dataIndex: 'date',
        key: 'date',
        fixed: printMode ? '' : 'left',
        filterDropdown: ({ confirm }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
            picker="date" 
              value={searchDateRange}
              onChange={dates => {
                  handleDateSearch(dates);
              }}
              locale={locale}
            />
            <div style={{ marginTop: 8 }}>
              <Button type="primary" htmlType="submit" onClick={() => {
                filterDataSourceByDate();
                confirm();
              }}>
                検索
              </Button>
              <Button type="default"  className="prev-button" onClick={() => {
                resetSearchDate();
                confirm();
              }}>
                リセット
              </Button>
            </div>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color:"#444"}} className='filter-icon'/>,
        render: date => (
          <>
            <span className='date-pc'>
              {(new Date(date)).getFullYear() + "年" + ((new Date(date).getMonth() + 1)) + "月" + (new Date(date).getDate()) + "日"}
            </span>
            <span className='date-smp'>
            {((new Date(date)).getFullYear()).toString().slice(-2) + "/" + ((new Date(date).getMonth() + 1)) + "/" + (new Date(date).getDate())}
            </span>
          </>
        ),
      },
    ];
    const aveTitleArray = [
      { title: '終値', dataIndex: 'ave1', key: 'ave1' },
    ];
    for(const ave of aveWArray){
      aveTitleArray.push({ title: `${ave}日平均`, dataIndex: `ave${ave}`, key: `ave${ave}` });
    }

    for (let i = 0; i < aveTitleArray.length; i++) {
      const average = aveTitleArray[i];
      
      columns.push({
        ...average,
        render: (ave) => (
          <div style={{ backgroundColor: (ave.rank == i && ave.value !== null) ? "rgba(255,0,0,0.7)" : (aveWArray.length - i == ave.rank && ave.value !== null) ? "rgba(0,255,0,0.7)" : ""}} className='cell-content'>
            <div>{ave.value}</div>
            <div>({aveWArray.length - ave.rank+1})【{(ave.rank == i && ave.value !== null) ? "買" : (aveWArray.length - i == ave.rank && ave.value !== null) ? "売" : ""}】</div>
          </div>
        )
      });
    }
    setNikkeColumns(columns);
  };

  const modalText =
  <>
    <div style={{textAlign:"center"}}><img src="/tradeSignalWRank1.png" width="260px" style={{border:"1px #444 solid"}}/></div>
    <div>終値の価格が一番高く、大きい順に終値・5日平均値・25日平均値・75日平均値になり、一列(日付の行以外)が緑色で揃った時は日経平均は高値圏に向かっているということなので、買っている人は売りを考えましょう。</div>
    <div>終値の価格が一番低く、小さい順に終値・5日平均値・25日平均値・75日平均値になり、一列(日付の行以外)が赤色に揃った時は日経平均は安値圏に向かって下落中です。安全のために自己資金を5分割し5回に分けて買うと安値圏の平均価格で買うことができます。</div>
    <div>なお、株価は上昇下落するので自己分析を加え自己責任で売買してください。</div>
  </>

  const printColumns = [
    { title: '日付', dataIndex: 'date', key: 'date' },
    { title: '終値', dataIndex: 'ave1', key: 'ave1' },
  ];

  const printArray = [{
    date: '日付',
    ave1: '終値',
  }];

  aveWArray.forEach(ave => {
      printColumns.push({ title: `${ave}日平均`, dataIndex: `ave${ave}`, key: `ave${ave}` });
      printArray[0][`ave${ave}`] = `${ave}日平均`;
  });

  const tableScroll = printMode ? { x:false, y: false} : { x: true, y: adjustedHeight };
  const pagenation = printMode ? false: { position: ['topRight','bottomRight']};

  useEffect(()=> {
    if(!printMode){
      setTableDataArray(nikkeiArray)
    }
  },[printMode])

  return (
    <>
    <Loading loading={isLoad}/>
    <PrintModal setPrintMode={setPrintMode} setTableDataArray={setTableDataArray} nikkeiArray={nikkeiArray} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
    <ExplanationModal title="移動平均線順位信号ページ" columnName="isExTradeSignalWRank" text={modalText} isExplanation={isExplanation}/>
    {printMode &&
      <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
    }
    <Table dataSource={tableDataArray} columns={nikkeiColumns} bordered scroll={tableScroll} pagination={pagenation} />
    </>
  );
};

export default TradeSignalWRankTable;
