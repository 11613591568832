import '../App.css';
import React, { useState, useEffect} from "react";
import { TradeSignalAllTable,HeaderGroup } from './components';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Button, Modal } from 'antd';
import TradeSimurationTable from './components/TradeSimurationTable';


const TradeSignalAll = () => {
  const [isModal,setIsModal] = useState(false);
  const [isSimurationModal,setIsSimurationModal] = useState(false);
  const [printMode, setPrintMode] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleClose = () =>{
    setIsModal(false);
    setIsSimurationModal(false)
  }

  const handleModal = () =>{
    setIsModal(true)
  }

  const handleSimurationModal = () =>{
    setIsSimurationModal(true)
  }

  const handlePrint = () =>{
    setIsPrintModal(true);
  }

  useEffect(() => {
    if(printMode){
      window.print();
    }
  }, [printMode]);

  useEffect(() => {
    const afterPrint = () => {
      setPrintMode(false);
      removeAllHiddenClasses();
    }
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  const removeAllHiddenClasses = () => {
    const elements = document.querySelectorAll(`.hidden`);
      elements.forEach(element => {
        element.classList.remove('hidden');
      });
  };

  const modalText = 
  <ul style={{listStyle:"decimal"}}>
  <li>本日の売買信号を見てください</li>
  <li>赤信号＝一回目まず買ってみましょう＝「一回目試し買いする」信号が出ているか確認してください</li>
  <li>銘柄番号をクリックするとその対応する信号のページに遷移します</li>
  <li>過去の株価推移をネットで検索し、考察してください</li>
  <li>売買信号ページにその銘柄の過去52週の高値と安値が表示されています</li>
  <li>画面右上の計算シミュレーターの空欄に数値を入力すると下落率と損益がわかります</li>
  </ul>

  return (

    <div className='back-content'>
        <HeaderGroup handlePrint={handlePrint}/>
        <PullToRefresh onRefresh={handleRefresh}>
        <Modal
          title="説明"
          open={isModal}
          onCancel={() => handleClose()}
          footer={[
            <span style={{textAlign:"start"}}>{modalText}</span>,
            <>
              <div style={{margin:"20px 0"}}>
                <Button type="primary"  onClick={handleClose}>
                    閉じる
                </Button>
              </div>
            </>
          ]}
        >
        </Modal>
        <Modal
          title="売買シミュレーション"
          open={isSimurationModal}
          onCancel={() => handleClose()}
          footer={[
            <span style={{textAlign:"start"}}><TradeSimurationTable/></span>,
            <>
              <div style={{margin:"20px 0"}}>
                <Button type="primary"  onClick={handleClose}>
                    閉じる
                </Button>
              </div>
            </>
          ]}
        >
        </Modal>
          <div className='body-content'>
            <div className="signal-label" style={{backgroundColor:"#888",color:"#fff"}}>本日の売買信号一覧</div>
            <div className="all-header">
              <div style={{margin:"5px"}}><Button onClick={e => handleModal()}>説明</Button></div>
              <div style={{margin:"5px"}}><Button type="primary"onClick={e => handleSimurationModal()}>売買シミュレーション</Button></div>
            </div>
            <TradeSignalAllTable printMode={printMode} setPrintMode={setPrintMode} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
          </div>
        </PullToRefresh>
    </div>

  );
};

export default TradeSignalAll;