import '../App.css';
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Radio,Select, DatePicker} from 'antd';
import { HeaderGroup } from './components';
import { useNavigate,useLocation } from 'react-router-dom';
import { getIndividualStock } from '../commonFunc';
import moment from 'moment';

const { Option } = Select;

const InsertMyHoldings = () => {
  const [error, setError] = useState('');
  const [isBuy,setIsBuy] = useState(true);
  const [individualArray,setIndividualArray] = useState([]);
  const [selectHolding,setSelectHolding] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const holdingId = queryParams.get('holdingId');

  const insert = async (values) => {
    try {
      const tradeDate = (new Date(values.tradeDate)).getFullYear() + "-" + ((new Date(values.tradeDate)).getMonth()+1) + "-" + (new Date(values.tradeDate)).getDate();
      const confirmDate = values.confirmDate !== null && values.confirmDate !== undefined ? 
        (new Date(values.confirmDate)).getFullYear() + "-" + ((new Date(values.confirmDate)).getMonth()+1) + "-" + (new Date(values.confirmDate)).getDate() : null;
        const response = await fetch(process.env.REACT_APP_API_DIR+'/myHoldings/insert', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({tradePrice:values.tradePrice,tradeDate,stockNum:values.stockNum,confirmPrice:values.confirmPrice,confirmDate,isBuy:values.isBuy,stockCode:values.stockCode}),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            setError(data.errorText);
          }
          else{
            console.log(data.errorText);
            alert(data.errorText);
            navigate(`/IndividualStock/MyHoldings?stock=${queryParams.get('stock')}`);
          }
        }
    } catch (error) {
        setError("ネットワークエラーです");
        console.error('Fetch error:', error);
    }
  };

  const update = async (values) => {
    try {
      const tradeDate = (new Date(values.tradeDate)).getFullYear() + "-" + ((new Date(values.tradeDate)).getMonth()+1) + "-" + (new Date(values.tradeDate)).getDate();
      const confirmDate = values.confirmDate !== null && values.confirmDate !== undefined ? 
        (new Date(values.confirmDate)).getFullYear() + "-" + ((new Date(values.confirmDate)).getMonth()+1) + "-" + (new Date(values.confirmDate)).getDate() : null;
        const response = await fetch(process.env.REACT_APP_API_DIR+'/myHoldings/update', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({holdingId,tradePrice:values.tradePrice,tradeDate,stockNum:values.stockNum,confirmPrice:values.confirmPrice,confirmDate,isBuy:values.isBuy,stockCode:values.stockCode}),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            setError(data.errorText);
          }
          else{
            console.log(data.errorText);
            alert(data.errorText);
            navigate(`/IndividualStock/MyHoldings?stock=${queryParams.get('stock')}`);
          }
        }
    } catch (error) {
        setError("ネットワークエラーです");
        console.error('Fetch error:', error);
    }
  };

  function  onChange(value){
    setIsBuy(value);
  }

  useEffect(() => {
    getIndividualStock().then(data =>{
      if(data.isError){
        console.log(data.errorText);
      }
      else{
        setIndividualArray(data.nikkeiArray);
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
    if(holdingId !== null){
      getHolding();
    }
  },[]);

  const getHolding = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/myHoldings/get/one', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({holdingId}),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            console.log(data.myHolding)
            setSelectHolding(data.myHolding)
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  };


  const tapPrev = () => {
    navigate(`/IndividualStock/MyHoldings?stock=${queryParams.get('stock')}`);
  };


  return (
    <div className='back-content'>
      <div className='main-content'>
        <HeaderGroup/>
        <div className='body-content'>
        <h2 className='page-title'>持ち株登録</h2>

          <div className='page-content'>
            {selectHolding === null &&
              <Form
                className="page-form"
                onFinish={insert}
              >
                {error && <p style={{ color: 'red', fontSize: '80%' }}>{error}</p>}
                <Form.Item
                  name="stockCode"
                  rules={[{ required: true, message: '売買した銘柄を選択してください' }]}
                  label={<span style={{fontSize:"120%"}}>売買した銘柄</span>}
                  labelCol={{span:24}}
                >
                  <Select
                    style={{fontSize:"120%", width: '100%'}}
                  >
                    {individualArray.length > 0 && individualArray.map((item) => (
                      <Option value={item.stockCode}><span style={{fontSize:"120%"}}>{item.stockName}({item.stockCode})</span></Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="tradePrice"
                  rules={[{ required: true, message: '売買時の株価を入力してください' }]}
                  label={<span style={{fontSize:"120%"}}>売買株価(円)</span>}
                  labelCol={{span:24}}
                >
                  <Input
                    style={{fontSize:"120%"}}
                    type="number"
                  />
                </Form.Item>
    
                <Form.Item
                    name="tradeDate"
                    rules={[{ required: true, message: '売買日を選択してください' }]}
                    label={<span style={{fontSize:"120%"}}>売買日</span>}
                    labelCol={{span:24}}
                  >
                    <DatePicker style={{fontSize:"120%"}} placeholder='売買日を選択'/>
                  </Form.Item>
                  <Form.Item
                    name="stockNum"
                    rules={[{ required: true, message: '売買枚数を入力してください' }]}
                    label={<span style={{fontSize:"120%"}}>売買株数(枚)</span>}
                    labelCol={{span:24}}
                  >
                    <Input
                      style={{fontSize:"120%"}}
                      type="number"
                    />
                  </Form.Item>
                  <Form.Item
                    name="isBuy"
                    rules={[{ required: true, message: '買いか売りかを選択してください' }]}
                    label={<span style={{fontSize:"120%"}}>買いか売りか</span>}
                    labelCol={{span:24}}
                  >
                    <Radio.Group onChange={e=>onChange(e.target.value)} value={isBuy} name="isBuy">
                      <Radio value={1}><span style={{fontSize:"120%"}}>買い</span></Radio>
                      <Radio value={0}><span style={{fontSize:"120%"}}>売り</span></Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="confirmPrice"
                    label={<span style={{fontSize:"120%"}}>利確時の株価(円)</span>}
                    labelCol={{span:24}}
                  >
                    <Input
                      style={{fontSize:"120%"}}
                      type="number"
                    />
                  </Form.Item>
                  <Form.Item
                      name="confirmDate"
                      label={<span style={{fontSize:"120%"}}>利確日</span>}
                      labelCol={{span:24}}
                    >
                      <DatePicker style={{fontSize:"120%"}} placeholder='利確日を選択'/>
                    </Form.Item>
    
                  <Button type="default"  className="prev-button" onClick={tapPrev}>
                    戻る
                  </Button>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    登録
                  </Button>
              </Form>
            }
            {selectHolding !== null &&
              <Form
                className="page-form"
                onFinish={update}
              >
                {error && <p style={{ color: 'red', fontSize: '80%' }}>{error}</p>}
                <Form.Item
                  name="stockCode"
                  rules={[{ required: true, message: '売買した銘柄を選択してください' }]}
                  label={<span style={{fontSize:"120%"}}>売買した銘柄</span>}
                  labelCol={{span:24}}
                  initialValue={selectHolding.stockCode}
                >
                  <Select
                    style={{fontSize:"120%", width: '100%'}}
                  >
                    {individualArray.length > 0 && individualArray.map((item) => (
                      <Option value={item.stockCode}><span style={{fontSize:"120%"}}>{item.stockName}({item.stockCode})</span></Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="tradePrice"
                  rules={[{ required: true, message: '売買時の株価を入力してください' }]}
                  label={<span style={{fontSize:"120%"}}>売買株価(円)</span>}
                  labelCol={{span:24}}
                  initialValue={selectHolding.tradePrice}
                >
                  <Input
                    style={{fontSize:"120%"}}
                    type="number"
                  />
                </Form.Item>
    
                <Form.Item
                    name="tradeDate"
                    rules={[{ required: true, message: '売買日を選択してください' }]}
                    label={<span style={{fontSize:"120%"}}>売買日</span>}
                    labelCol={{span:24}}
                    initialValue={moment(selectHolding.tradeDate)}
                  >
                    <DatePicker style={{fontSize:"120%"}} placeholder='売買日を選択' />
                  </Form.Item>
                  <Form.Item
                    name="stockNum"
                    rules={[{ required: true, message: 'を入力してください' }]}
                    label={<span style={{fontSize:"120%"}}>売買株数(枚)</span>}
                    labelCol={{span:24}}
                    initialValue={selectHolding.stockNum}
                  >
                    <Input
                      style={{fontSize:"120%"}}
                      type="number"
                    />
                  </Form.Item>
                  <Form.Item
                    name="isBuy"
                    rules={[{ required: true, message: '買いか売りかを選択してください' }]}
                    label={<span style={{fontSize:"120%"}}>買いか売りか</span>}
                    labelCol={{span:24}}
                    initialValue={selectHolding.isBuy}
                  >
                    <Radio.Group onChange={e=>onChange(e.target.value)} value={isBuy} name="isBuy">
                      <Radio value={1}><span style={{fontSize:"120%"}}>買い</span></Radio>
                      <Radio value={0}><span style={{fontSize:"120%"}}>売り</span></Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="confirmPrice"
                    label={<span style={{fontSize:"120%"}}>利確時の株価(円)</span>}
                    labelCol={{span:24}}
                    initialValue={selectHolding.confirmPrice}
                  >
                    <Input
                      style={{fontSize:"120%"}}
                      type="number"
                    />
                  </Form.Item>
                  <Form.Item
                      name="confirmDate"
                      label={<span style={{fontSize:"120%"}}>利確日</span>}
                      labelCol={{span:24}}
                      initialValue={selectHolding.confirmDate && moment(selectHolding.confirmDate)}
                    >
                      <DatePicker style={{fontSize:"120%"}} placeholder='利確日を選択'/>
                    </Form.Item>
    
                  <Button type="default"  className="prev-button" onClick={tapPrev}>
                    戻る
                  </Button>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    更新
                  </Button>
              </Form>
            }
          </div>
        </div>
      </div>
    </div>

  );
};

export default InsertMyHoldings;
