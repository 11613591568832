// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useLayoutEffect} from "react";
// import { getUserInfo} from '../../commonFunc';
import { Table, Modal, Button} from 'antd';
import { useNavigate,useLocation } from 'react-router-dom';
import Loading from "./Loading";
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { getIndividualStock } from '../../commonFunc';
import IndustryStock from '../IndustryStock';
dayjs.locale('ja');


const FundamentalsTable = (data) => {
  const { printMode } = data;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [adjustedHeight, setAdjustedHeight] = useState(0);

  const [nikkeiArray,setNikkeiArray] = useState([]);
  const [isLoad,setIsLoad] = useState(false);
  const navigate = useNavigate();
  
  const [tableDataArray,setTableDataArray] = useState(null);


  useEffect(() => {
    setIsLoad(true)
    getIndividualStock().then(data =>{
      if(data.isError){
        console.log(data.errorText);
      }
      else{
        setNikkeiArray(data.nikkeiArray);
      }
      setIsLoad(false)
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });

  },[]);

  useEffect(() => {
    setTableDataArray(nikkeiArray);
  },[nikkeiArray]);

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });


  const handleIndividualStock = (stockCode) =>{
    navigate(`/IndividualStock/TradeSignal?ave=20&stock=${stockCode}`);
  }

  const columns = [
    { title: '銘柄コード', dataIndex: 'stockCode', key: 'stockCode',
      render: stockCode => (
        <>
          <Button  onClick={() => handleIndividualStock(stockCode)}>{stockCode}</Button>
        </>
      ),
    },
    { title: '銘柄名', dataIndex: 'stockName', key: 'stockName'},
    { title: '業種名', dataIndex: 'industryName', key: 'industryName'},
    { title: 'PER', dataIndex: 'PER', key: 'PER' ,
      render: data => (
        <>
          {data &&
            <>{data}倍</>
          }
        </>
      ),
    },
    { title: 'PBR', dataIndex: 'PBR', key: 'PBR' ,
      render: data => (
        <>
          {data &&
            <>{data}倍</>
          }
        </>
      ),
    },
    { title: '配当利回り', dataIndex: 'dividendYield', key: 'dividendYield' ,
      render: data => (
        <>
          {data &&
            <>{data}%</>
          }
        </>
      ),
    },
    { title: '貸借倍率', dataIndex: 'creditMultiplier', key: 'creditMultiplier' ,
      render: data => (
        <>
          {data &&
            <>{data}倍</>
          }
        </>
      ),
    },
    { title: '更新日', dataIndex: 'updateDate', key: 'updateDate' ,
      render: date => (
        <>
          {date &&
            <>
              <span className='date-pc'>
                {(new Date(date)).getFullYear() + "年" + ((new Date(date).getMonth() + 1)) + "月" + (new Date(date).getDate()) + "日"}
              </span>
              <span className='date-smp'>
              {((new Date(date)).getFullYear()).toString().slice(-2) + "/" + ((new Date(date).getMonth() + 1)) + "/" + (new Date(date).getDate())}
              </span>
            </>
          }
        </>
      ),
    },
  ];


const printColumns = [
  { title: '銘柄コード', dataIndex: 'stockCode', key: 'stockCode'},
  { title: '銘柄名', dataIndex: 'stockName', key: 'stockName'},
  { title: '業種名', dataIndex: 'industryName', key: 'industryName'},
  { title: 'PER', dataIndex: 'PER', key: 'PER' },
  { title: 'PBR', dataIndex: 'PBR', key: 'PBR' },
  { title: '配当利回り', dataIndex: 'dividendYield', key: 'dividendYield' },
  { title: '貸借倍率', dataIndex: 'creditMultiplier', key: 'creditMultiplier' },
  { title: '更新日', dataIndex: 'updateDate', key: 'updateDate' },
];

const printArray = [{
  stockCode: '銘柄コード',
  stockName:'銘柄名',
  industryName:'業種名',
  PER: 'PER',
  PBR: 'PBR',
  dividendYield: '配当利回り',
  creditMultiplier: '貸借倍率',
  updateDate:'更新日'
}];


const tableScroll = printMode ? { x:false, y: false} : { x: true, y: adjustedHeight };
const pagenation = printMode ? false: { position: ['topRight','bottomRight']};

  return (
    <>
      <Loading loading={isLoad}/>
      {printMode &&
        <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false}/>
      }
      <Table dataSource={tableDataArray} columns={columns} bordered scroll={tableScroll} pagination={pagenation} />
    </>
   
  );
};

export default FundamentalsTable;
