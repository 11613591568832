import './App.css';
import React, { useState ,useEffect} from "react";
import { Form, Input, Button ,Typography} from 'antd';
import { LockOutlined,MailOutlined,EyeTwoTone,EyeInvisibleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { loginCheck } from './commonFunc';
const { Link } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading,setLoding] = useState(false);
  const [showPassword,setShowPassword] = useState(false);
  const onSubmit = (values) => {
    const fetchData = async () => {
      try {
        setLoding(true);
          const response = await fetch(process.env.REACT_APP_API_DIR+'/login', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({mail:values.email,password:values.password}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              setError(data.errorText);
            }
            else{
              if(data.isExplanation){
                navigate("/Explanation");
              }
              else{
                navigate("/NikkeiStock");
              }
              
            }
          }
      } catch (error) {
        console.log(error);
          setError("ネットワークエラーです");
      } finally{
        setLoding(false);
      }
    };
    fetchData();
  };

  useEffect(() => {
    loginCheck().then(data =>{
      if(data){
        navigate("/NikkeiStock");
      }
    }).catch(err=>{
      console.log(err);
    });
  },[]);

  return (
    <div className='back-content'>
      <div className='main-content'>

        <Form
          name="normal_login"
          className="common-form"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
        >
        <h1 className='form-title'>ログイン</h1>
          {error && <p style={{ color: 'red', fontSize: '80%' }}>{error}</p>}
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'メールアドレスを入力してください' }]}
            label={<span style={{fontSize:"120%"}}>メールアドレス</span>}
            labelCol={{span:24}}
          >
            <Input prefix={<MailOutlined />}
              style={{fontSize:"120%"}}
              type='email'
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'パスワードを入力してください' }]}
            label={<span style={{fontSize:"120%"}}>パスワード</span>}
            labelCol={{span:24}}
          >
            <Input.Password
              style={{fontSize:"120%"}}
              prefix={<LockOutlined />}
              type="password"
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              visibilityToggle={{ visible: showPassword, onVisibleChange: setShowPassword }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" >
            <Spin spinning={loading}  />ログイン
            </Button>
          </Form.Item>
            <Link className="login-form-forgot" href="/PasswordReset">
              パスワードを忘れた
            </Link>
        </Form>
      </div>
    </div>

  );
};

export default Login;
