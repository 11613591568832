import './App.css';
import React, { useState, useEffect } from "react";
import { getUserInfo} from './commonFunc';
import {Button} from 'antd';
import {HeaderGroup } from './components';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

const MyPage = () => {
  const navigate = useNavigate();
  const tapPasswordChange = () =>{
    navigate("/ChangePassword");
  }
  
  const tapUserEdit = () =>{
    navigate("/EditUserInfo");
  }
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        setUserInfo(data.user);
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
    
  },[]);
  return (

    <div className='back-content'>
      <HeaderGroup/>
      <div className='body-content'>
      <div className='page-title'><h2>マイページ</h2></div>
      {userInfo ? (
        <div className='mypage-content'>
        <div style={{display:"inline-block"}}>
        {/* {userInfo.isAdmin ? <div className='info-label'>あなたは管理者に設定されています</div> :<></>} */}
        
          <div className='user-content'>
            <div className='user-section'>
              <p className='user-title'>お名前</p>
              <p className='user-text'>{userInfo.name}</p>
            </div>
            <div className='user-section'>
              <p className='user-title'>メールアドレス</p>
              <p className='user-text'>{userInfo.mail}</p>
            </div>
            <div className='user-section'>
              <p className='user-title'>投資金額※1回の売買では投資金額の20％まで</p>
              <p className='user-text'>{(Number(userInfo.investment)).toLocaleString()}円</p>
            </div>
            <div className='user-section'>
              <p className='user-title'>日経平均株価の入力方法</p>
              <p className='user-text'>{userInfo.isAuto ? "自動" :"手動"}</p>
            </div>
            <div className='user-section'>
              <p className='user-title'>日経平均株価の入力を手動に切り替えた日</p>
              <p className='user-text'>{userInfo.manualChangeDate ? (new Date(userInfo.manualChangeDate)).getFullYear()+"年"+((new Date(userInfo.manualChangeDate)).getMonth()+1)+"月"+(new Date(userInfo.manualChangeDate)).getDate()+"日" : ""}</p>
            </div>
            <div className='user-section'>
              <p className='user-title'>メールマガジン</p>
              <p className='user-text'>{userInfo.isMailRev ? "受け取る" :"受け取らない"}</p>
            </div>
            <div className='btn-group'>
              <Button type="primary" className="mypage-button" onClick={tapUserEdit}>
                ユーザー情報編集
              </Button>
              <Button type="primary" className="mypage-button" onClick={tapPasswordChange}>
                パスワード変更
              </Button>
            </div>
          </div>
        </div>
        </div>
      )  : (
        <div className='loading'>
          <Spin spinning={!userInfo} size="large" />
        </div>
      )

        }
      </div>
    </div>

  );
};

export default MyPage;
