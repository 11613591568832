import '../App.css';
import React, { useState, useEffect } from "react";
import {Typography,Button} from 'antd';
import {HeaderGroup } from './components';
const { Link } = Typography;

const ManualIndividual = () => {
  
  const [isInitManual, setIsInitManual] = useState(false);
  const [isAmaterasuManual, setIsAmaterasuManual] = useState(false);
  const [isExPageManual, setIsExPageManual] = useState(false);
  const [isLogicalManual, setIsLogicalManual] = useState(false);
  useEffect(() => {
  },[]);
  const handleInitManual = () =>{
    setIsInitManual(true);
  }
  const handleExPageManual = () =>{
    setIsExPageManual(true);
  }
  const handleAmaterasuManual = () =>{
    setIsAmaterasuManual(true);
  }
  const handleLogicalManual = () =>{
    setIsLogicalManual(true);
  }

  const handlePrev = () =>{
    setIsExPageManual(false);
    setIsInitManual(false);
    setIsAmaterasuManual(false);
    setIsLogicalManual(false);
  }
  return (

    <div className='back-content'>
      <HeaderGroup/>
      <div className='body-content'>
      <div className='page-title'><h2>マニュアル</h2></div>
      <div className='mypage-content'>
        {(!isInitManual && !isExPageManual && !isAmaterasuManual && !isLogicalManual) ? (
          <div className='user-content'>
            {/* <Link className="manual-link" onClick={handleAmaterasuManual}>
                <p>株式天照ソフトとは</p>
            </Link>
            <Link className="manual-link" onClick={handleInitManual}>
                <p>初期設定マニュアルを表示</p>
            </Link> */}
            <Link className="manual-link" onClick={handleExPageManual}>
                <p>ページ説明マニュアルを表示</p>
            </Link>
            {/* <Link className="manual-link" onClick={handleLogicalManual}>
                <p>株式天照ソフトの理論について</p>
            </Link> */}
          </div>
        )  : (
          <>
            <div className='manual-content'>
            {isAmaterasuManual && 
                <>
                <h3>株式天照ソフトとは</h3>
                <div style={{margin:"20px 0" }}>
                    <p>初めての投資や安全投資を考えている人のために日経平均をAI人工知能で自動的に分析したソフトです。</p>
                    <p>　</p>
                    <p>本ソフトを携帯で立ち上げてデータを見るだけでいつ売買したらよいかがわかります。</p>
                    <p>日経平均株価が安くなったとき＝買いましょうの文字と赤色の信号が出ます</p>
                    <p>日経平均株価が高くなったとき＝売りましょうの文字と緑色の信号が出ます</p>
                    <p>　</p>
                    <p>赤信号が50個～63個付くと年間の安値圏です。</p>
                    <p>この時は日経平均に含まれている70%の個別株が安値圏ですので、安くなっている銘柄を選んで買うとリスクが少ない場所です。</p>
                    <p>緑色の信号が出たら上昇していますので、自分の満足の上昇幅3%～20%で自己責任で売りましょう。</p>
                    <p>　</p>
                    <p>日経平均連動商品は銀行・信用金庫・郵貯・保険会社等で聞いてください。こちらの銘柄は倒産リスクがほとんどないので安全です。</p>
                    <p>証券会社で買える日経平均連動型投信などの銘柄も安全です。</p>
                    <p>1口5万弱で買えますので、初心者に適した商品です。</p>
                    <p>　</p>
                    <p>本ソフトは株式理論に独自目線の数値計算で作成した他にはない文字信号型ソフトです。</p>
                    <p>銀行に1000万預けても0.002%の金利で年間200円の利息なので、年1回の売買信号で買えば銀行金利よりも何百倍になる。</p>
                    <p>この位の気持ちで運用するのがリスクの少ない売買手法です。</p>
                    <p>　</p>
                    <p>株式理論についての説明対応は一切できませんので、理論を知りたい方はご自身で研究をお願い致します。</p>
                  </div>
              </>
            }
            {isInitManual && 
              <>
                <h3>株式天照ソフト(アマテラス)へようこそ</h3>
                <div style={{margin:"20px 0" }}>
                    <p>本ソフトはAI人工知能を駆使し、売買タイミングを知らせるソフトです。</p>
                    <p>まずは下記の手順に従い設定を行いましょう。</p>
                    <ul style={{listStyle:"decimal"}}>
                        <li>
                            右上の人型アイコンをタップ→マイページ→ユーザー情報編集画面から投資金額（日経平均株の投資に使える金額）を入力し、更新ボタンを押す。
                        </li>
                        <li>                                
                            続いてパスワード変更ボタンよりパスワード変更画面を開き、現在のパスワード(初期パスワード)と新しいパスワードを2回入力して更新ボタンを押す。
                        </li>
                        <li>
                            これで設定は完了です。画面左のメニュー(スマホの場合は左上の三本線ボタン)より日経平均株価と日経平均の売買タイミングを確認できます。
                        </li>
                    </ul>
                    <p>本ソフトをスマートフォンのホーム画面に表示する方法</p>
                    <ul style={{listStyle:"decimal"}}>
                        <li>
                            LINEから開いている場合は画面右下の︙(縦三点リーダ)をタップし、ブラウザを開くを選択する。(既にブラウザで開いている場合は省略)
                        </li>
                        <li>                                
                            <p>その後ブラウザでの表示に切り替わったら端末ごとで下記設定を行う。</p>
                            <p>【iPhoneの場合】</p>
                            <p>　画面下部の真ん中にある共有ボタン(四角から↑が飛び出した記号)をタップし、表示されたメニューの中の「ホーム画面に追加」を選択する。</p>
                            <p>【Androidの場合】</p>
                            <p>　画面右上の︙(縦三点リーダ)をタップし、表示されたメニューの中の「ホーム画面に追加」を選択する。</p>
                        </li>
                    </ul>
                    <p>本ソフトをPCのデスクトップ上に表示する方法</p>
                        <ul>
                            <li>
                                PCにインストールされているブラウザで「https://amaterasu-stock.com/」にアクセスする
                            </li>
                            <li>                                
                                アクセスしたらURL部分を全選択する
                            </li>
                            <li>                                
                               URLが選択されたら左クリックをしながらデスクトップ画面にカーソルを動かし、左クリックを離す
                            </li>
                        </ul>
                  </div>
              </>
            }
            {isExPageManual && 
              <>
                <div>
                <h3>本日の売買信号一覧ページ</h3>
                <div>
                  <div>このソフトは日経平均225銘柄をAI人工知能で分析し、下落（上昇）始めた個別銘柄を表示します。</div>
                  <div>225種株価の終値が下がると赤色・上がると緑で表示されます。</div>
                  <div>また約1年半前までの過去の日経平均株価を確認することができます。</div>
                  <div>20日30日60日75日90日120日150日の移動平均線対比毎に銘柄表示と赤や緑の信号が表示されます。</div>
                  <div>世界情勢や思惑により上記のどこかで反転しますので自己判断にて売買して下さい。（天照独自指標）</div>
                  <div>天照の売買はリスク分散売買を薦めています、1回目売買信号・2回目売買信号・3回目売買信号が出るたびに価格は下がりますので、下落ごとに3回～5回の平均法を薦めています。</div>
                  <div>最後は急落位置の大バーゲンセールの信号が出ますので、安全投資に心がけ自己責任で売買して下さい。</div>
                  <div>下落信号１回～５回が出たら投資金額の１０%～３０%を分散投資して下さい。1度信号が出てもその後の下落が５回あると思って下さい。</div>
                  <div>儲けるではなくリスク排除の投資法(イコール)買値平均法を薦めています。</div>
                </div>
              </div>
              <div>
                <h3>150日間前日対比信号ページ</h3>
                <div>
                  <div>本日3日5日7日10日20日25日30日60日75日90日120日150日間の終値と前日価格を対比し、全ての前日対比がマイナスの時は赤色、プラスの時緑色で表示されます。</div>
                  <div>横一線全てが赤信号になると安値圏、緑信号になると高値圏です。</div>
                  <div>本日の終値と150日間までの終値の平均値をそれぞれ表示しています。</div>
                  <div>自己分析指標と組み合わせ判断材料として下さい。（天照独自指標）</div>
                </div>
              </div>
              <div>
                <h3>移動平均線順位信号ページ</h3>
                <div>
                  <div>終値・5日25日75日の移動平均線チャートを価格教示した画期的テクニカル天照指標です。</div>
                  <div>終値の価格が一番低く、小さい順に終値・5日平均値・25日平均値・75日平均値になり、一列(日付の行以外)が赤色に揃った時は日経平均は安値圏に向かって下落中です。安全のために自己資金を5分割し5回に分けて買うと安値圏の平均価格で買うことができます。</div>
                  <div>チャートによる移動平均価格分析は順張り・逆張り両方に使えますので売買に利用下さい。</div>
                  <div>画像を見ただけでは価格や上昇　下落率等は確定値はわかりませんが、移動平均線順位信号・終値対平均値差信号ページを見るだけで移動平均線が色と文字で一目瞭然にわかります。(天照独自指標です)</div>
                  <p><img src="/moveAveLine.jpg" width="260px" style={{border:"1px #444 solid"}}/>　←左画像は通常皆さんが見ているチャートです</p>
                </div>
              </div>
              <div>
                <h3>終値対平均値差信号ページ</h3>
                <div>
                  <div>終値と5日25日75日の差を表示しており、終値とのカイリ率を赤色・緑色で表示します。</div>
                  <div>三点の差の合計が10％以上の時安値圏～高値圏と判断します。（過去の分を検証し自己判断で売買して下さい）</div>
                  <div>※自己責任で売買してください。</div>
                </div>
              </div>
              <div>
                <h3>12日間投資家心理ページ</h3>
                <div>
                  <div>過去12日間に上昇した日数を12で割り、投資家心理を12日間に先取りし、売られ・買われ過ぎを見ます。</div>
                  <div>25％は売られ過ぎ～75％以上は買われ過ぎと判断し赤色と緑色の心理的信号として出します。</div>
                </div>
              </div>
            </>
            }
            {isLogicalManual && 
              <>
                <h3>株式天照ソフトの理論について</h3>
                <div style={{margin:"20px 0" }}>
                    <p>株価の上昇下落は三角形ピラミッド型で形成される。</p>
                    <p>終値が三角形ABCのB頂点に達したときが高値圏＝売りのタイミング</p>
                    <p>終値が三角形ABCのBからCになったときが下落となり安値圏＝買いのタイミング</p>
                    <p><img src="/logical1.png" width="280px" style={{border:"1px #444 solid"}}/></p>
                    <p>　</p>
                    <p>売買信号ページの売買信号は株式理論日数の平均価格の順位により日経平均株価の下落および上昇ごとに6回の信号が出ます。</p>
                    <p>6回目の買い信号(売買信号➆ページ)が出るのはコロナなどの投売り時などです。</p>
                    <p>通常3～4回目の買いましょうの信号(売買信号➃～売買信号➄ページ)で上昇することが多いです。</p>
                    <p>この3～4回目の下落時の平均価格で買って上昇を待つソフトです。</p>
                    <p>　</p>
                    <h4>過去の売買場所</h4>
                    <p>2021年8月20日　買い　27013円</p>
                    <p>2021年9月14日　売り　30670円　1株当たり3657円(13.5%)の利益</p>
                    <p>100万円分売買した場合→13万5000円の利益</p>
                    <p>　</p>
                    <p>2021年9月14日　売り　30670円　高値で売っておき下落で買い戻す</p>
                    <p>2022年3月9日　買い戻し　24717円　1株当たり5953円(19.4%)の利益</p>
                    <p>100万円分売買した場合→19万4000円の利益</p>
                    <p>2022年3月9日の27013円はコロナの時なので赤色の信号で「大バーゲンセール中買おう」の信号がでました。その年の最安値です。</p>
                    <p>その後売り信号は2023年6月6日の32506円～2024年3月5日の40097円まで上がりました。どこで売っても利益になるような信号が出ていました。</p>
                    <p>　</p>
                    <p>売買は安いときに買い、高いときに売る。また高いときに売り、買い戻す手法もできます(空売り)。</p>
                    <p>　</p>
                    <p>　</p>
                    <p>投資に慣れた方は毎朝8時過ぎにその日の日経平均予測価格を表示していますのでそちらをご確認ください。(日経平均予測値ページ)</p>
                    <p>オプション機能でしたがサービスで通常機能にしましたので、デイトレード・225先物取引等にご利用ください。</p>
                    <p>※自己責任でご使用ください</p>
                    <p>　</p>
                    <p>ローソク足の形と位置・移動平均線・グランビルの法則・値動きの反転場所・チャートパターン・Wトップ・三尊天井やRSI・ストキャスティクス・RCI・乖離率・MACD・ボリンジャーバンド・PER・PBRなどのテクニカル指標を考慮し、独自目線で作成したソフトです。</p>
                    <p>　</p>
                    <p style={{color:"red"}}>上記に関しての質問の対応は一切できませんので独自で勉強するようにお願い致します。</p>
                  </div>
              </>
            }
                  <div className='btn-group'>
                    <Button type="primary" className="manual-button" onClick={handlePrev}>
                      一覧へ戻る
                    </Button>
                  </div>
              </div>
            </>
          )
        }
      </div>
    </div>
  </div>

  );
};

export default ManualIndividual;
