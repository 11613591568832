// src/DataTable.js

import React, { useState, useEffect, useLayoutEffect} from "react";
import { getUserInfo} from '../commonFunc';
import { Table,DatePicker, Button } from 'antd';
import './components.css';
import Loading from "./Loading";
import { SearchOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import ExplanationModal from './ExplanationModal';
import { useNavigate } from 'react-router-dom';
import PrintModal from './PrintModal';
dayjs.locale('ja');

const { RangePicker } = DatePicker;

const TwelvePsychologyTable = (data) => {
  const { printMode ,setPrintMode,isPrintModal,setIsPrintModal} = data;
  const [adjustedHeight, setAdjustedHeight] = useState(0);

  const [nikkeiArray,setNikkeiArray] = useState([]);
  const [isLoad,setIsLoad] = useState(false);

  const [tableDataArray,setTableDataArray] = useState(null);
  const [searchDateRange, setSearchDateRange] = useState([]);

  const [isExplanation,setIsExplanation] = useState(false);

  const navigate = useNavigate();

  const handleDateSearch = (dates) => {
    setSearchDateRange(dates);
  };

  const filterDataSourceByDate = (data) => {
    if (!searchDateRange || searchDateRange.length < 1) {
      setTableDataArray(data);
      return;
    }

    const [start, end] = searchDateRange;
    setTableDataArray( data.filter(item => {
      const itemDate = new Date(item.date);
      (start.$d).setHours(0,0,0,0,0);
      (end.$d).setHours(0,0,0,0,0);
      return itemDate.getTime() >= (start.$d).getTime() && itemDate.getTime() <= (end.$d).getTime();
    }));
  };

  const resetSearchDate = () => {
    setTableDataArray(nikkeiArray);
    setSearchDateRange([]);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
          setIsLoad(true);
          const response = await fetch(process.env.REACT_APP_API_DIR+'/nikkeiStock/get/twelvePsychology', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({}),
          });

          const data = await response.json();
          if(data){
            if(data.isError){
              console.log(data.errorText);
            }
            else{
              setNikkeiArray(data.nikkeiArray);
            }
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
      setIsLoad(false);
    };
    fetchData();
    getUserInfo().then(data =>{
      if(data.isError){
        navigate("/");
      }
      else{
        setIsExplanation(data.user.isExTwelvePsycho);
      }
    }).catch(err=>{
      console.log(err);
      navigate("/");
    });
  },[]);

  useEffect(() => {
    resetSearchDate();
  },[nikkeiArray]);

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  
  const columns = [
    { 
      title: '日付',
      dataIndex: 'date',
      key: 'date',
      fixed: printMode ? '' : 'left',
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            value={searchDateRange}
            onChange={dates => {
                handleDateSearch(dates);
            }}
            locale={locale}
          />
          <div style={{ marginTop: 8 }}>
            <Button type="primary" htmlType="submit" onClick={() => {
              filterDataSourceByDate(nikkeiArray);
              confirm();
            }}>
              検索
            </Button>
            <Button type="default"  className="prev-button" onClick={() => {
              resetSearchDate();
              confirm();
            }}>
              リセット
            </Button>
          </div>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color:"#444"}} className='filter-icon'/>,
      render: date => (
        <>
          <span className='date-pc'>
            {(new Date(date)).getFullYear() + "年" + ((new Date(date).getMonth() + 1)) + "月" + (new Date(date).getDate()) + "日"}
          </span>
          <span className='date-smp'>
          {((new Date(date)).getFullYear()).toString().slice(-2) + "/" + ((new Date(date).getMonth() + 1)) + "/" + (new Date(date).getDate())}
          </span>
        </>
      ),
    },
    { title: '終値', dataIndex: 'closePrice', key: 'closePrice' },
    { title: '前日比', dataIndex: 'beforeDayDiff', key: 'beforeDayDiff',
        render: beforeDayDiff => (
            <>
            {beforeDayDiff > 0 ? (
                <span style={{color:"green"}}>
                +{beforeDayDiff}
                </span>
            ) : (
              <>
                {beforeDayDiff === 0 ? (
                  <span style={{color:"black"}}>
                  {beforeDayDiff}
                  </span>
                ) : (
                  <span style={{color:"red"}}>
                  {beforeDayDiff}
                  </span>
                )}
              </>
            )}
            </>
        ),
    },
    { title: '12日間のプラス率(%)', dataIndex: 'plusRatioTwelve', key: 'plusRatioTwelve',
    render: plusRatioTwelve => {
      if(plusRatioTwelve === null){
        return (
          <div className='cell-content'>
           <div>{plusRatioTwelve}</div>
          </div>
        );
      }
      else if(plusRatioTwelve >= 74) {
        return (
          <div style={{ backgroundColor: "rgba(0,255,0,0.7)"}} className='cell-content'>
           <div>{plusRatioTwelve}</div>
          </div>
        );
      }
      else if(plusRatioTwelve <= 34 ){
        return (
          <div style={{ backgroundColor: "rgba(255,0,0,0.7)"}} className='cell-content'>
           <div>{plusRatioTwelve}</div>
          </div>
        );

      }   
      else{
        return (
          <div className='cell-content'>
           <div>{plusRatioTwelve}</div>
          </div>
        );

      }
  }         
},
  ];

  const modalText =
  <>
    <div>本ページは日経平均株価の売買のタイミングを知ることができるページです。</div>
    <div>12日間のプラス率(一番右側の行)に本日から12日前までの中で株価が上昇した日の割合を表示しています。</div>
    <div>12日間のプラス率のセルの色が赤色になれば買い、緑色になれば売りのタイミングです。</div>
    <div>※本ページは上級者向けのページのため、信号が出たときに売買するか、いくら売買するかはご自身の判断で行ってください。</div>
  </>

  const printColumns = [
    { title: '日付', dataIndex: 'date', key: 'date' },
    { title: '終値', dataIndex: 'ave1', key: 'ave1' },
    { title: '前日比', dataIndex: 'beforeDayDiff', key: 'beforeDayDiff'},
    { title: '12日間のプラス率(%)', dataIndex: 'plusRatioTwelve', key: 'plusRatioTwelve'}
  ];

  const printArray = [{
    date: '日付',
    ave1: '終値',
    beforeDayDiff:'前日比',
    plusRatioTwelve:'12日間のプラス率(%)'
  }];


  const tableScroll = printMode ? { x:false, y: false} : { x: true, y: adjustedHeight };
  const pagenation = printMode ? false: { position: ['topRight','bottomRight']};

  useEffect(()=> {
    if(!printMode){
      setTableDataArray(nikkeiArray)
    }
  },[printMode])

  return (
    <>
      <Loading loading={isLoad}/>
      <ExplanationModal title="12日間投資家心理ページ" columnName="isExTwelvePsycho" text={modalText} isExplanation={isExplanation}/>
      <PrintModal setPrintMode={setPrintMode} setTableDataArray={setTableDataArray} nikkeiArray={nikkeiArray} isPrintModal={isPrintModal} setIsPrintModal={setIsPrintModal}/>
      {printMode &&
        <Table dataSource={printArray} columns={printColumns} bordered scroll={{ x: false, y: false}} pagination={false} />
      }
      <Table dataSource={tableDataArray} columns={columns} bordered scroll={tableScroll} pagination={pagenation} />
    </>
  );
};

export default TwelvePsychologyTable;
